<div class="container mt-4 d-flex flex-column justify-content-center align-items-center">
    <div class="w-100">
      <mat-card class="fitler d-flex justify-content-between">
        <div>
          <mat-form-field class="input-field">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search for a record " #input>
          </mat-form-field>
        </div>
      </mat-card>
      <div class="table-container mat-elevation-z8 mt-3">
        <table mat-table [dataSource]="dataSource">
      
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class=""> No </th>
              <td mat-cell *matCellDef="let user">{{user.id}}</td>
            </ng-container>          
            <!-- Name Column -->
        
            <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef class=""> Full name </th>
                <td mat-cell *matCellDef="let user">{{user.displayName}}</td>
            </ng-container> 
    
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef class=""> Email </th>
                <td mat-cell *matCellDef="let user">{{user.email}}</td>
            </ng-container> 
    
    
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="admin-certificate-table-th">
                  Actions
              </th>
              <td mat-cell *matCellDef="let user">
                  <div class="d-flex align-middle">
                    <button class="btn-actions">
                        <mat-icon class="delete-ico" (click)="removeUser(user.id)">delete</mat-icon>
                    </button>
                  </div>                 
              </td>            
          </ng-container> 
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator class="table-paginator" [pageSizeOptions]="[5, 10, 25,pageFullSizeOption]"                
                         showFirstLastButtons>
          </mat-paginator>
      </div>
    </div>
      <div class="spacer"></div>
  </div>