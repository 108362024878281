import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '@profect/v7-core-lib';
import { SMTUserCertificate, UserCertificateDTO } from '../model/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserCertificateService {

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
  };

  constructor(
    private http:HttpClient,
    protected apiService: ApiService
  ) { }

  getControllerPath() {
    return `${this.apiService.getServerUrl()}v1/user-certificate/`;
  }

  getCurrentUserCertificates(): Observable<SMTUserCertificate[]> {
    return this.http.get<SMTUserCertificate[]>(`${this.getControllerPath()}current-user-certifications`,
    this.httpOptions);
  }

  addCertificate(userCertificate: UserCertificateDTO): Observable<SMTUserCertificate> {
    return this.http.post<SMTUserCertificate>(`${this.getControllerPath()}add-certificate`,
    JSON.stringify(userCertificate),
    this.httpOptions)
  }

  updateCertificate(userCertificate: SMTUserCertificate):Observable<SMTUserCertificate> {
    return this.http.put<SMTUserCertificate>(`${this.getControllerPath()}update-certificate`,
    JSON.stringify(userCertificate),
    this.httpOptions);
  }

  removeCertificate(id: number):Observable<any> {
    return this.http.delete<any>(`${this.getControllerPath()}remove-certificate?id=` + id,
    this.httpOptions);
  }
}
