<div class="container mt-4">
    <mat-card class="fitler">
      <mat-form-field class="input-field">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search for a record " #input>
      </mat-form-field>
    </mat-card>
    <div class="table-container mat-elevation-z8 mt-3">
        <table mat-table [dataSource]="dataSource">
          
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? toggleAllRows() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>


          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let skill">{{skill.id}}</td>
          </ng-container>          
          <!-- Group Column -->
          <ng-container matColumnDef="skillGroup">
            <th mat-header-cell *matHeaderCellDef> Group </th>
            <td mat-cell *matCellDef="let skill"> 
              <div *ngIf="editMode">
                <mat-form-field  *ngIf="editMode && selectedSkill?.id === skill.id" class="w-90 input-field">
                  <mat-label>Group</mat-label>
                  <input matInput placeholder="Change group" [(ngModel)]="selectedSkill.skillGroup" [value]="selectedSkill.skillGroup" pattern="[\x00-\x7F]+" required>                                                      
                </mat-form-field>                
              </div>
              <div *ngIf="selectedSkill?.id !== skill.id">
                {{skill.skillGroup}}
              </div>
            </td>
          </ng-container>
      
          <!-- Subgroup Column -->
          <ng-container matColumnDef="skillSubgroup">
            <th mat-header-cell *matHeaderCellDef> Subgroup </th>
            <td mat-cell *matCellDef="let skill"> 
              <div *ngIf="editMode">
                <mat-form-field *ngIf="editMode && selectedSkill?.id === skill.id" class="w-90 input-field">
                  <mat-label>Subgroup</mat-label>
                  <input matInput placeholder="Change subgroup" [(ngModel)]="selectedSkill.skillSubgroup" [value]="selectedSkill.skillSubgroup" pattern="[\x00-\x7F]+" required>
                </mat-form-field>
              </div>
              <div *ngIf="selectedSkill?.id !== skill.id">
                {{skill.skillSubgroup}}                
              </div>
            </td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="skillName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let skill"> 
              <div *ngIf="editMode">
                <mat-form-field *ngIf="editMode && selectedSkill?.id === skill.id" class="w-90 input-field">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="Change name" [(ngModel)]="selectedSkill.skillName" [value]="selectedSkill.skillName" pattern="[\x00-\x7F]+" required>
                </mat-form-field>
              </div>
              <div *ngIf="selectedSkill?.id !== skill.id">
                {{skill.skillName}}
              </div>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef >
                Actions
            </th>
            <td mat-cell *matCellDef="let skill" >
                <div class="d-flex align-middle">
                  <button class="btn-action-white-bg btn-edit" (click)="editSkill(skill)" *ngIf="selectedSkill?.id !== skill.id">
                    <mat-icon class="edit-ico">edit</mat-icon>
                  </button>
                  <button class="btn-actions">
                    <mat-icon class="delete-ico" (click)="removeSkill(skill.id)">delete</mat-icon>
                  </button>   
                  <button class="btn-action-white-bg" (click)="saveSkillChanges()" *ngIf="editMode && selectedSkill?.id === skill.id">
                    <mat-icon class="save-ico">save</mat-icon>
                  </button>                  
                  <div class="pl-3">
                    <mat-checkbox class="mt-1" color="primary" (change)="isSkillActive($event,skill)" [checked]="skill.isActive">
                      <span  *ngIf="!skill.isActive" class="skill-disabled font-italic ml-2">Disabled</span>
                    </mat-checkbox>                                                                                      
                  </div>                  
                </div>                 
            </td>            
        </ng-container> 
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator class="table-paginator" [pageSizeOptions]="[5, 10, 20,50,100,200,pageFullSizeOption]"                
                       showFirstLastButtons>
        </mat-paginator>
        <div class="action-panel">
          <button class="btn-action-white-bg" (click)="editSelected()" [disabled]="this.selection.selected.length == 0">
            <mat-icon class="edit-ico">edit</mat-icon>
          </button> 
          <button mat-raised-button class="btn-action" (click)="setAddSkillMode()">
            <mat-icon class="btn-skill-add-ico btn-ico">add</mat-icon>
            Add skill
          </button>
          <button mat-raised-button class="btn-action" (click)="disableSelected()">
            <mat-icon class="btn-ico">cancel</mat-icon>
            Disable selected
          </button>          
        </div>
    </div>
    <form  *ngIf="addSkillMode">
      <mat-card class="edit-panel-card mt-3 p-5">
        <mat-form-field class="input-field w-25">
          <mat-label>Group</mat-label>
          <input matInput name="group" #group="ngModel" placeholder="Enter group or select" [(ngModel)]="newSkill.group" [matAutocomplete]="addGroup" pattern="[\x00-\x7F]+" required>
          <mat-hint *ngIf="group.errors?.['required'] && (group.dirty || group.touched)" style="color:red;">SKill group is required</mat-hint>
          <mat-hint *ngIf="group.errors?.['pattern']" style="color:red;">Non ascii characters!</mat-hint>
          <mat-autocomplete autoActiveFirstOption #addGroup="matAutocomplete">
            <mat-option *ngFor="let skill of allSkills | uniqueArr : 'skillGroup'"  [value]="skill.skillGroup">
              {{skill.skillGroup}}
            </mat-option>
          </mat-autocomplete>   
        </mat-form-field>
        <mat-form-field class="input-field w-25">
          <mat-label>Subgroup</mat-label>
          <input matInput name="subgroup" #subgroup="ngModel" placeholder="Enter group or select" [(ngModel)]="newSkill.subGroup" [matAutocomplete]="addSubgroup" pattern="[\x00-\x7F]+" required>
          <mat-hint *ngIf="subgroup.errors?.['required'] && (subgroup.dirty || subgroup.touched)" style="color:red;">SKill subgroup is required</mat-hint>
          <mat-hint *ngIf="subgroup.errors?.['pattern']" style="color:red;">Non ascii characters!</mat-hint>
          <mat-autocomplete autoActiveFirstOption #addSubgroup="matAutocomplete">
            <mat-option *ngFor="let skill of allSkills | uniqueArr : 'skillSubgroup'"  [value]="skill.skillSubgroup">
              {{skill.skillSubgroup}}
            </mat-option>
          </mat-autocomplete>  
        </mat-form-field>
        <mat-form-field class="input-field w-25">
          <mat-label>Skill name</mat-label>
          <input matInput name="name" #name="ngModel" placeholder="Enter group or select" [(ngModel)]="newSkill.name" pattern="[\x00-\x7F]+" required>
          <mat-hint *ngIf="name.errors?.['required'] && (name.dirty || name.touched)" style="color:red;">SKill name is required</mat-hint>
          <mat-hint *ngIf="name.errors?.['pattern']" style="color:red;">Non ascii characters!</mat-hint>
        </mat-form-field>
        <button mat-raised-button class="btn-action btn-add" type="submit"
         (click)="addNewSkill()"
         [disabled]="group.invalid || subgroup.invalid || name.invalid">
          <mat-icon class="btn-skill-add-ico">add</mat-icon>
          Add skill
        </button>
      </mat-card>
    </form>
    <form *ngIf="editSelectedSkillsdMode && selection.selected.length != 0">
      <mat-card class="edit-panel-card mt-3 p-5">
        <div class="w-75">
          <mat-form-field class="input-field mr-3 w-40">
            <mat-label>Group</mat-label>
            <input matInput name="group" #editGroupInput="ngModel" placeholder="Enter group or select" [(ngModel)]="skillGroup" [matAutocomplete]="editGroup" pattern="[\x00-\x7F]+">        
            <mat-hint *ngIf="editGroupInput.errors?.['pattern']" style="color:red;">Non ascii characters!</mat-hint>
            <mat-autocomplete autoActiveFirstOption #editGroup="matAutocomplete">
              <mat-option *ngFor="let skill of allSkills | uniqueArr : 'skillGroup'"  [value]="skill.skillGroup">
                {{skill.skillGroup}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>            
          <mat-form-field class="input-field w-40">
            <mat-label>Subgroup</mat-label>
            <input matInput name="subgroup" #editSubgroupInput="ngModel" placeholder="Enter group or select" [(ngModel)]="skillSubgroup" [matAutocomplete]="editSubgroup" pattern="[\x00-\x7F]+">
            <mat-hint *ngIf="editSubgroupInput.errors?.['pattern']" style="color:red;">Non ascii characters!</mat-hint>
            <mat-autocomplete autoActiveFirstOption #editSubgroup="matAutocomplete">
              <mat-option *ngFor="let skill of allSkills | uniqueArr : 'skillSubgroup'"  [value]="skill.skillSubgroup">
                {{skill.skillSubgroup}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>      
        <button mat-raised-button class="btn-action btn-save" type="submit"
         (click)="saveSelectedSkillsChanges()" [disabled]="editGroupInput.invalid || editSubgroupInput.invalid">
          <mat-icon class="btn-action-save-ico btn-ico">save</mat-icon>
          Save
        </button>
      </mat-card>
    </form>
    <div class="spacer"></div>
</div>