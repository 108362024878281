import { Component, OnInit, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { indexOf, result } from 'lodash';
import { Role, SMTUser, SMTUserSkill, Skill, UserSkillDTO } from 'src/app/model/models';
import { SkillService } from 'src/app/services/skill.service';
import { UserSkillService } from 'src/app/services/user-skill.service';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from "@angular/material/dialog";
import { AdminPopupComponent } from '../admin-popup/admin-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-skill-overview',
  templateUrl: './skill-overview.component.html',
  styleUrls: ['./skill-overview.component.scss']
})
export class SkillOverviewComponent implements OnInit{

  // Search field vars

  searchFieldLabels: string[] = ["Group", "Subgroup", "Skill"];
  
  groupSearchFieldControl = new FormControl();
  subgroupSearchFieldControl = new FormControl();
  skillSearchFieldControl = new FormControl();

  groupSearchFieldValue: string;
  subgroupSearchFieldValue: string;
  skillSearchFieldValue: string;


  // Action modes vars
  
  addSkillMode: boolean = false;
  editSkillMode: boolean = false;

  // Select vars

  selectedGroup: string;
  selectedSubgroup: string;
  selectedSkill: Skill;
  selectedUserSkill: SMTUserSkill = {} as SMTUserSkill;

  // Search List vars
  
  skillGroupList: string[];
  skillSubgroupList: string[];
  skillNameList: string[];

  // User vars

  userSkills: SMTUserSkill[] = [];
  userSkillLevel: number;

  userRoles:Role[] = [];
  lastUpdateAt: Date;

  // Skill vars
  skillsList: Skill[] = [];
  suggestedSkillList: Skill[] = [];

  // Mat table vars

  displayedColumns: string[] = ['skillGroup', 'skillSubgroup', 'skillName', 'skillLevel','actions'];
  dataSource = new MatTableDataSource<SMTUserSkill>();
  @ViewChild(MatSort) sort: MatSort;

  // Email vars
  reportEmail: string;
  email:string = "GlobalKnowledge@synpulse.com; businessdevelopment@synpulse.com";
  subject:string = "New skill suggestion";
  body: string = "Requested skill (skill name):%0d%0aSkill Group:%0d%0aSkill Subgroup:%0d%0aReason for request:";


  // Other vars 
  Arr = Array;
  levelValueList: number[] = [1,2,3,4,5];
  hoveredStars: number;
  isIdenticalSubgroup = false;

  constructor(
    private skillService:SkillService, 
    private userSkillService:UserSkillService,
    private userService: UserService,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
    ) { }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }


  ngOnInit(): void {
    this.reportEmail = `mailto:${this.email}?subject=${this.subject}&body=${this.body}`;
    this.getData();
  }

  // Get data 

  getData() {

    this.skillService.getAllSkills().subscribe(
      skillList => {
        this.skillsList = skillList.sort(this.compareSkillName);
      }
    );

    this.skillService.getSkillGroupList().subscribe(
      skillGroupList => this.skillGroupList = skillGroupList.sort()
    );

    this.skillService.getSkillSubgroupList().subscribe(
      skillSubgroupList => this.skillSubgroupList = skillSubgroupList.sort()
    );

    this.skillService.getSkillNameList().subscribe(
      skillNameList => this.skillNameList = skillNameList.sort()
    );

    this.getSkillsData();

  }

  getLastUpdateDate(): void {
    this.userService.getUserTimestamp().subscribe(
      date => this.lastUpdateAt = date
    );
  }


  getSkillsData(): void {
    this.userSkillService.getCurrentUserSkills().subscribe(userSkills => {
      this.dataSource = new MatTableDataSource(userSkills);
      this.userSkills = userSkills;
      this.refreshTable();
    });
  }

  // Skills actions 

  addSkill() {
    this.addSkillMode = true;
    this.editSkillMode = false;
  }

  addNewSkill() {
    let newSkill: UserSkillDTO = {
      level: this.userSkillLevel,
      skillId: this.selectedSkill.id
    }

    this.userSkillService.addSkill(newSkill).subscribe(userSkill => 
      {
        this.userSkills.push(userSkill);
        this.refreshTable();
      });
    
    
    this.selectedSkill = undefined;
    this.hoveredStars = 0;
    this.userSkillLevel = 0;
  }

  
  editSkill(userSkill:SMTUserSkill) {
    this.addSkillMode = false;
    this.editSkillMode = true;
    this.selectedUserSkill = userSkill;
    this.selectedGroup = userSkill.skill.skillGroup;
    this.selectedSubgroup = userSkill.skill.skillSubgroup;
    this.selectedSkill = userSkill.skill;
    this.userSkillLevel = userSkill.level;
    this.hoveredStars = 0;
    this.userSkillLevel = 0;
  }

  hoverSkillLevel(level:number) {
    this.hoveredStars = level;
  }

  unhoverSkillLevel() {
    this.hoveredStars = 0;
  }

  setSkillLevelLegend(level: number) {
    switch(level) {
      case 1:
        return "* Novice. \n\n".concat("You have a fundamental understanding of the skill. You comprehend basic concepts, definitions and terminologies involved. You have little to no practical experience. You feel confident enough to participate in minor skill-related tasks within a project team heavily relying on project leadership.");
      case 2:
        return "** Basic. \n\n".concat("You have a broader understanding of the skill. You comprehend basic concepts, definitions and terminologies involved and you can effectively utilize them. You have practical experience in this skill. You feel confident enough to participate in basic skill-related tasks within a project team relying on project leadership.");
      case 3:
        return "*** Intermediate. \n\n".concat("You have a complex understanding of the skill. You can effectively apply your knowledge to solve problems, make decisions and undertake more complex tasks related to the skill. You have solid experience in this skill- e.g., minimum 2 years of experience / experience from multiple projects. You feel confident enough to participate in complex skill-related tasks within a project team as well as assume responsibility for delivering results on your own, not relying on project leadership.");
      case 4:
        return "**** Advanced. \n\n".concat("You feel very confident in your ability regarding the skill. You can adapt to complex situations involving this skill, conduct research, act as a team leader, and develop new solutions and methods if necessary. You have at least 5 years of active experience or a minimum of 5 projects worth of experience. You feel confident enough to assume full responsibility for end-to-end delivery of complex project tasks and act as a project lead.");
      case 5:
        return "***** Expert. \n\n".concat("You consider yourself an authority in the skill and your peers acknowledge this as well. Your knowledge and experience combined demonstrate the ability to teach others, assume full responsibility and become a subject matter expert and leader. You feel confident enough to develop and manage very complex project tasks or lead a large project team.");
    }
  }

  removeSkill(userSkill:SMTUserSkill) {
    this.userSkillService.removeSkill(userSkill.id).subscribe(res => {      
      this.getSkillsData();
    }      
    );
    this.setAddMode();
  }

  saveChanges() {
    this.selectedUserSkill.skill = this.selectedSkill;
    if(this.userSkillLevel) {
      this.selectedUserSkill.level = this.userSkillLevel; 
    } 
    this.userSkillService.updateSkill(this.selectedUserSkill).subscribe( s => this.getLastUpdateDate());   
    this.selectedSkill = undefined;
    this.hoveredStars = 0;
    this.userSkillLevel = 0;
    this.setAddMode();
  }

  skillInList(skill: Skill):boolean {
    let userSkill = this.userSkills.find( us => us.skill.id == skill.id)
    if (userSkill != null || userSkill != undefined)
      return true;      
    return false;
  }

  // Set actions 

  setSearchFieldValueGroup(fieldValue: string) {
    this.groupSearchFieldValue = fieldValue;
  }

  setSearchFieldValueSubgroup(fieldValue: string) {
    this.subgroupSearchFieldValue = fieldValue;
  }

  setSearchFieldValueSkill(fieldValue: string) {
    this.skillSearchFieldValue = fieldValue;
  }

  setUserSkillLevel(userSkillLevel: number) {
    this.userSkillLevel = userSkillLevel;
  }

  // Select actions 

  selectGroup(skillGroup:string) {
    this.selectedSkill = undefined;
    this.selectedSubgroup = undefined;
    
    this.selectedGroup = skillGroup;

    this.skillService.getSkillSubgroup(skillGroup).subscribe(
      subgroups => {
        if(subgroups.length == 1 && this.selectedGroup == subgroups[0]) {
          this.isIdenticalSubgroup = true;
          this.selectSubgroup(subgroups[0]);
          this.skillSubgroupList = [];          
        } else {
          this.isIdenticalSubgroup = false;
          this.skillSubgroupList = subgroups.sort();
        }               
      }
    );
  }

  selectSubgroup(skillSubgroup) {
    this.selectedSubgroup = skillSubgroup;
    this.selectedSkill = undefined;
    this.skillService.getSkills(skillSubgroup).subscribe(
      skills => {   
        if(this.selectedGroup) {
          this.selectedGroup = skills.filter((skill) => skill.skillGroup == this.selectedGroup)[0].skillGroup;
        } else {
          this.selectedGroup = skills[0].skillGroup;
        }        
        this.skillsList = [];
        for(let item of skills) {
          if(this.skillsList.indexOf(item) === -1) {
            this.skillsList.push(item);
          }
        }
        this.skillsList.sort(this.compareSkillName);
      }
    );
  }

  selectSkill(skill: Skill) {
      this.selectedGroup = skill.skillGroup;
      this.selectedSubgroup = skill.skillSubgroup;
      this.selectedSkill = skill;
  }

  selectRecommendedSkill(skill: Skill) {
    this.selectSkill(skill);
  }

  // Refresh actions

  refreshFilter() {
    this.skillService.getAllSkills().subscribe(
      skillList => {
        this.skillsList = skillList;
      }
    )

    this.skillService.getSkillGroupList().subscribe(
      skillGroupList => this.skillGroupList = skillGroupList
    );
    this.skillService.getSkillSubgroupList().subscribe(
      skillSubgroupList => this.skillSubgroupList = skillSubgroupList
    );
    this.skillService.getSkillNameList().subscribe(
      skillNameList => this.skillNameList = skillNameList
    );

    this.selectedGroup = undefined;
    this.selectedSubgroup = undefined;
    this.selectedSkill = undefined;
  }

  refreshTable() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'skillGroup': return item.skill.skillGroup;
        case 'skillSubgroup': return item.skill.skillSubgroup;
        case 'skillName': return item.skill.skillName;
        case 'skillLevel': return item.level;
        default: return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource._updateChangeSubscription();
    this.getLastUpdateDate();

    // Update suggested skills
    this.userSkillService.getSuggestedSkills().subscribe(
      skills => this.suggestedSkillList = skills?.sort(this.compareSkillName)
    );
  }

  // Admin functions 

  onAdminClick() {
    const dialogRef = this.dialog.open(AdminPopupComponent, {
      panelClass: 'admin-modal-page'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  // Mode functions 

  setAddMode() {
    this.editSkillMode = false;
    this.addSkillMode = true;
  }

  // Other actions

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  logout() {
    this.userService.logoutUser().subscribe();
  }

  // Data operations

  compareSkillName(a: Skill, b: Skill) {
    if (a.skillName < b.skillName){
      return -1;
    }
    if (a.skillName > b.skillName){
      return 1;
    }
    return 0;
  }
  

}
