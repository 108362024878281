<app-header></app-header>
<div class="imp-exp mt-3">
    <button class="btn-imp-exp mr-3" mat-raised-button (click)="onAdminClick()" >
        <mat-icon>import_export</mat-icon>
    </button>
</div>

<div class="container">
  <mat-tab-group dynamicHeight>
    <mat-tab label="Skills">
      <app-admin-skill-overview [allSkills]="allSkills" [dataSource]="dataSource" [pageFullSizeOption]="pageFullSizeOption" (setPaginatorEvent)="getPaginator($event)"></app-admin-skill-overview>
    </mat-tab>
    <mat-tab label="Languages">
      <app-admin-language-overview></app-admin-language-overview>
    </mat-tab>
    <mat-tab label="Certificates">
      <app-admin-certifications-overview></app-admin-certifications-overview>
    </mat-tab>
    <mat-tab label="Users">
      <app-admin-users-overview></app-admin-users-overview>
    </mat-tab>
  </mat-tab-group>
  <div class="nav-v-control">
    <button mat-fab  class="btn-fab btn-up" (click)="goToTop()">
      <mat-icon class="arrow-ico">keyboard_arrow_up</mat-icon>
    </button>
    <button mat-fab  class="btn-fab btn-down" (click)="goToBottom()">
      <mat-icon class="arrow-ico">keyboard_arrow_down</mat-icon>
    </button>
  </div>
</div>
