import { Component, OnInit, ViewChild } from "@angular/core";
import { FindSkillOverviewComponent } from "../find-skill-overview/find-skill-overview.component";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
  selector: "app-main-page",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.scss"],
})
export class MainPageComponent implements OnInit {
  @ViewChild(FindSkillOverviewComponent)
  findSkillOverview: FindSkillOverviewComponent;

  constructor() {}

  ngOnInit(): void {}

  onTabChanged(event: MatTabChangeEvent) {
    if (event.index === 3) {
      if (this.findSkillOverview) {
        this.findSkillOverview.loadSkills();
      }
    }
  }
}
