<mat-card class="search-card">
    <app-search-field [searchFieldLabel]="objectName | titlecase" [Control]="searchFieldControl" (searchEvent)="setSearchFieldValue($event)"></app-search-field>        
    <mat-action-list class="search-list-items">
        <button
            mat-list-item
            [matTooltip]="item.name.length > 45 ? item.name : ''"            
            *ngFor="let item of searchList | searchFilter:searchFieldValue:objectName"
            (click)="selectItem(item)"
            [class.selected]="item.id === selectedItem?.id"
            [class.disabled-item]="itemInList(item)"
            [disabled]="itemInList(item)" 
        >
            <span class="truncate-text">
                {{item[property]}}
            </span>            
        </button>
    </mat-action-list>
</mat-card>
