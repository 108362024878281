import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'searchFilter'})

export class SearchFilter implements PipeTransform {

  transform(collecion,filterText,searchField) {
    if(searchField == 'skill') {
      return collecion ? collecion.filter(item => item.skillName.search(new RegExp(filterText, 'i')) > -1) : [];  
    } else if(searchField == 'language' || searchField == 'certificate') {  
      return collecion ? collecion.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];  
    } else {
      return collecion ? collecion.filter(item => item.search(new RegExp(filterText, 'i')) > -1) : [];  
    }
    
  }

}