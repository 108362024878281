import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss']
})
export class SearchContainerComponent implements OnInit {

  @Input() searchList: any[];
  @Input() userDataList: any[];
  @Input() property: string;
  @Input() objectName: string;
  @Output() selectItemEvent = new EventEmitter<number>();

  @Input() selectedItem: any;

  searchFieldControl = new FormControl();
  searchFieldValue: string;
  
  constructor() { }

  ngOnInit(): void {
  }

  setSearchFieldValue(fieldValue: string) {
    this.searchFieldValue = fieldValue;
  }

  selectItem(item: any) {
    this.selectedItem = item;
    this.selectItemEvent.emit(item);
  }

  itemInList(item: any):boolean {
    let element = this.userDataList ? this.userDataList.find( elem => elem[this.objectName].id == item.id) : null;
    if (element != null || element != undefined)
      return true;      
    return false;
  }

}
