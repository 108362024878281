<div class="container">
  <div class="skill mt-4">
    <div>
      <div
        class="w-100 mt-3 d-flex"
        [class.skill-search]="!isIdenticalSubgroup"
      >
        <mat-card
          class="skill-search-card"
          [class.skill-search-group]="isIdenticalSubgroup"
        >
          <app-search-field
            [searchFieldLabel]="searchFieldLabels[0]"
            [Control]="groupSearchFieldControl"
            (searchEvent)="setSearchFieldValueGroup($event)"
          ></app-search-field>
          <mat-action-list class="search-list-items">
            <button
              [appScrollIntoView]="skillGroup === selectedGroup"
              [class.selected]="skillGroup === selectedGroup"
              mat-list-item
              *ngFor="
                let skillGroup of skillGroupList
                  | searchFilter : groupSearchFieldValue : 'group'
              "
              (click)="selectGroup(skillGroup)"
            >
              {{ skillGroup }}
            </button>
          </mat-action-list>
        </mat-card>
        <mat-card
          class="skill-search-subgroup skill-search-card"
          *ngIf="!isIdenticalSubgroup"
        >
          <app-search-field
            [searchFieldLabel]="searchFieldLabels[1]"
            [Control]="subgroupSearchFieldControl"
            (searchEvent)="setSearchFieldValueSubgroup($event)"
          ></app-search-field>
          <mat-action-list class="search-list-items">
            <button
              [appScrollIntoView]="skillSubgroup === selectedSubgroup"
              [class.selected]="skillSubgroup === selectedSubgroup"
              mat-list-item
              *ngFor="
                let skillSubgroup of skillSubgroupList
                  | searchFilter : subgroupSearchFieldValue : 'subgroup'
              "
              (click)="selectSubgroup(skillSubgroup)"
            >
              {{ skillSubgroup }}
            </button>
          </mat-action-list>
        </mat-card>
        <mat-card class="skill-search-name skill-search-card">
          <app-search-field
            [searchFieldLabel]="searchFieldLabels[2]"
            [Control]="skillSearchFieldControl"
            (searchEvent)="setSearchFieldValueSkill($event)"
          ></app-search-field>
          <mat-action-list class="search-list-items">
            <button
              [matTooltip]="skill.skillName.length > 33 ? skill.skillName : ''"
              [class.selected]="skill.id === selectedSkill?.id"
              mat-list-item
              *ngFor="
                let skill of skillsList
                  | searchFilter : skillSearchFieldValue : 'skill'
              "
              (click)="selectSkill(skill)"
            >
              <span class="truncate-text">{{ skill.skillName }}</span>
            </button>
          </mat-action-list>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="spacer"></div>

  <app-search-field
    *ngIf="selectedSkill"
    [searchFieldLabel]="locationFieldLabel"
    [Control]="locationFilterFieldControl"
    (searchEvent)="setLocationFilterValue($event)"
  ></app-search-field>

  <div *ngIf="selectedSkill" clsas="skill-overview">
    <mat-card class="skill-overview-card">
      <mat-card-title class="d-flex justify-content-between align-items-center">
        <div>{{ selectedSkill.skillName }}</div>

        <div *ngIf="lastUpdateAt != null">
          <p class="m-0 text-muted">
            Last update:<span class="ml-2">{{
              lastUpdateAt | date : "mediumDate"
            }}</span>
          </p>
        </div>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="announceSortChange($event)"
          class="skill-overview-table"
        >
          <!-- Person Column -->
          <ng-container matColumnDef="person">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by name"
            >
              Person
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.smtUser.displayName }}
            </td>
          </ng-container>

          <!-- SkillLevel Column -->
          <ng-container matColumnDef="skillLevel">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by skillLevel"
            >
              Level
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon
                class="star"
                *ngFor="let i of Arr(element.level).fill(1)"
                >star</mat-icon
              >
            </td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by email"
            >
              Email
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.smtUser.email }}
            </td>
          </ng-container>

          <!-- Location Column -->
          <ng-container matColumnDef="location">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by location"
            >
              Location
            </th>
            <td mat-cell *matCellDef="let element">
              {{ getLocation(element.smtUser.netSuiteId) }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-divider></mat-divider>
      </mat-card-content>
    </mat-card>
  </div>
</div>
