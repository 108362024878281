import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@profect/v7-core-lib';
import { Language } from '../model/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
  };

  constructor(
    private http:HttpClient,
    protected apiService: ApiService
  ) { }

  getControllerPath() {
    return `${this.apiService.getServerUrl()}v1/language/`;
  }
  
  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(`${this.getControllerPath()}all`,
    this.httpOptions);
  }

}
