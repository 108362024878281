import { Component, OnInit } from '@angular/core';
import { Certificate, SMTUserCertificate, UserCertificateDTO } from 'src/app/model/models';
import { MatTableDataSource} from '@angular/material/table';
import { CertificateService } from 'src/app/services/certificate.service';
import { UserCertificateService } from 'src/app/services/user-certificate.service';




@Component({
  selector: 'app-certifications-overview',
  templateUrl: './certifications-overview.component.html',
  styleUrls: ['./certifications-overview.component.scss']
})


export class CertificationsOverviewComponent implements OnInit {

  viewTitle: string = 'Certifications overview';
  objectName: string = 'certificate';
  property: string = 'name';
  selectedCertificate: Certificate;
  certificates: Certificate[];
  userCertificates: SMTUserCertificate[];
  dataSource = new MatTableDataSource<SMTUserCertificate>();
  certificateIssuerName: string;
  issueDate: Date;   
  changedUserCertificate: SMTUserCertificate;
  reportEmail: string;
  email:string = "GlobalKnowledge@synpulse.com; businessdevelopment@synpulse.com";
  subject:string = "New certificate suggestion";
  body: string = "Requested certificate (name):%0d%0aReason for request:%0d%0aValidation period:";
  maxDateToPick:Date = new Date();
  displayedColumns:string[] = [    
    'name',
    'issueName',
    'issueDate',
    'monthsToExpire',
    'isValid',
    'actions'
  ];

  constructor(
    private certificateService: CertificateService,
    private userCertificateService: UserCertificateService 
  ) { 

  }

  ngOnInit(): void {
    this.reportEmail = `mailto:${this.email}?subject=${this.subject}&body=${this.body}`;
    this.getCertificates();
    this.getUserCertificates();
  }


  
  getCertificates() {
    this.certificateService.getCertificates().subscribe(
      certificates => {
        certificates.sort((a,b) => this.sortByName(a,b));
        let first = certificates.shift();
        certificates.push(first);
        this.certificates = certificates;        
      }
    )
  }

  getUserCertificates() {
    this.userCertificateService.getCurrentUserCertificates().subscribe(
      userCertificates => {      
        this.userCertificates = userCertificates;
      }
    )
  }

  sortByName(a,b) {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }

  selectCertification(certificate: Certificate) {
    this.selectedCertificate = certificate;
  }

  addCertificate() {
    this.issueDate = new Date(this.issueDate.getTime() + Math.abs(this.issueDate.getTimezoneOffset()*60000));            
    let newCertificate: UserCertificateDTO = {
      certificateId: this.selectedCertificate.id,
      issuerName: this.certificateIssuerName,
      issueDate: this.issueDate
    };    

    this.userCertificateService.addCertificate(newCertificate).subscribe(
      data => {
        this.getUserCertificates();
      }
    );

    this.selectedCertificate = undefined;
    this.certificateIssuerName = undefined;
    this.issueDate = undefined;
  }

  removeCertificate(id: number) {    
    this.userCertificateService.removeCertificate(id).subscribe(
      data => {
        this.getUserCertificates();
      }
    )
  }

  getUntilValid(element) {  
    let date = new Date(element.issueDate);     
    return new Date(date.setMonth(date.getMonth() + element.certificate.monthsToExpire));        
  }

}
