import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../services/login/auth/auth.guard';
import { AdminPageComponent } from '../components/admin-page/admin-page.component';
import { MainPageComponent } from '../components/main-page/main-page.component';

const routes:Routes = [
    {path:"admin",component:AdminPageComponent,canActivate:[AuthGuard]},
    {path:"**",component:MainPageComponent,canActivate:[AuthGuard]}    
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
  })
export class AppRoutingModule {}