<div class="d-flex align-items-center flex-column w-100 h-100 p-2">
    <h1>Import / Export</h1>
    <mat-expansion-panel [expanded]="importPanelOpenState" class="w-90 mh-50px mb-2" (opened)="onImportClick()"
    (closed)="importPanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            Import
          </mat-panel-title>
          <mat-panel-description>
            Import your CSV
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="d-flex p-4 justify-content-center">
            <div class="dropdiv row align-items-center justify-content-center"
            (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
                <label class="custom-file-upload h2">
                    <input (change)="onChange($event)" type="file" accept="text/csv"/>
                    Drop your file or 
                    <span class="browse badge rounded-pill bg-success p-2">Browse for file</span>
                </label>
            </div>
        </div>
        <div class="d-flex pl-4 pr-4 pb-2">
            <div class="file-badges d-flex" *ngFor="let file of csvFiles">
                <mat-icon *ngIf="!file.isLoaded">autorenew</mat-icon>
                <mat-icon *ngIf="file.isLoaded">done_outline</mat-icon>
                <p>{{file.name}}</p>
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="exportPanelOpenState" class="download w-90 mh-50px mb-2" (opened)="onExportClick()"
    (closed)="exportPanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            Export
          </mat-panel-title>
          <mat-panel-description>
            Export CSV
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
            <div class="w-100 text-center">
                <mat-form-field class="w-50" appearance="fill">
                    <mat-label>Download data</mat-label>
                    <mat-select [(value)] = "currentPreset" (selectionChange)="onPresetChanged($event.value)">
                      <mat-option *ngFor="let preset of presets" [value]="preset">
                        {{preset.name}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="d-flex justify-content-center w-100 pb-3">
                <div class="w-50">
                    <mat-checkbox [checked]="isAdvanceChecked" color="primary" class="checkbox"
                        (change)="onAdvancedCheck($event.checked)">
                        Advanced settings
                    </mat-checkbox>
                </div>
            </div>
            <div class="w-100 text-center pb-4">
                <button mat-raised-button class="btn btn-success w-50" (click)="onDownloadClick()">Export</button>
            </div>
            <mat-expansion-panel hideToggle [expanded]="advancedPanelOpenState" class="advance-panel w-100 mh-50px">
                <mat-expansion-panel-header class="advance-panel-header p-0">
                    <mat-panel-title>
                        Advanced settings:
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="w-100 h-100">
                    <div cdkDropListGroup class="drop-container row user-select-none w-100 mb-4 mt-4">
                        <div class="drop-card col-sm-3" [ngClass]="{'highlight': hasHighlightFirstKeys}">
                            <h2 class="w-100 text-left">{{currentPreset.columnNames[0]}}</h2>
                        
                            <div
                                cdkDropList
                                [cdkDropListData]="userKeys"
                                class="drop-list"
                                cdkDropListSortingDisabled
                                (cdkDropListDropped)="drop($event)"
                                [cdkDropListEnterPredicate]="noReturnPredicate">
                                <mat-card class="drop-item m-2" 
                                    *ngFor="let item of userKeys" 
                                    (cdkDragStarted)="dragFromUserOrSkillStarted($event)"
                                    cdkDrag 
                                    [cdkDragData]="item">{{item}}
                                </mat-card>
                            </div>
                        </div>
                        <div class="drop-card col-sm-3" [ngClass]="{'highlight': hasHighlightSecondKeys}">
                            <h2 class="w-100 text-left">{{currentPreset.columnNames[1]}}</h2>
                        
                            <div
                                cdkDropList
                                [cdkDropListData]="skillKeys"
                                class="drop-list"
                                cdkDropListSortingDisabled
                                (cdkDropListDropped)="drop($event)"
                                [cdkDropListEnterPredicate]="noReturnPredicate">
                                <mat-card class="drop-item m-2" 
                                    *ngFor="let item of skillKeys" 
                                    (cdkDragStarted)="dragFromUserOrSkillStarted($event)"
                                    cdkDrag 
                                    [cdkDragData]="item">{{item}}
                                </mat-card>
                            </div>
                        </div>
                        <div class="drop-card col-sm-3" [ngClass]="{'highlight': hasHighloghtCSVKeys}">
                            <h2 class="w-100 text-left">CSV File keys:</h2>
                        
                            <div
                                cdkDropList
                                [cdkDropListData]="csvKeys"
                                class="drop-list"
                                (cdkDropListDropped)="drop($event)">
                                <mat-card class="drop-item m-2" 
                                    *ngFor="let item of csvKeys" 
                                    (cdkDragStarted)="dragStarted($event)" 
                                    cdkDrag 
                                    [cdkDragData]="item">{{item}}
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <div class="w-100">
                        <div class="mt-4 mb-4">
                            <h2 class="w-100 text-left">CSV Preview:</h2>
                            <mat-table [dataSource]="dataSource">

                                <ng-container *ngFor="let column of csvKeys" matColumnDef="{{column}}" >
                
                                    <mat-header-cell *matHeaderCellDef class="preview-header">
                                        {{column}}
                                    </mat-header-cell>
                                    
                                    <mat-cell *matCellDef="let element" class="preview-cell">
                                        <p>{{getCellData(column, element)}}</p>
                                    </mat-cell>
                        
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="csvKeys; sticky: true"></mat-header-row>
                
                                <mat-row *matRowDef="let row; columns: csvKeys;"></mat-row>
                            </mat-table>       
                        </div>
                        <div class="w-100 text-center pb-1">
                            <button mat-raised-button class="btn btn-success w-50" (click)="onDownloadClick()">Export</button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-expansion-panel>
</div>
