import { Injectable, Injector } from '@angular/core';
import { ApiService, ClassService } from "@profect/v7-core-lib";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Certificate, CertificateDTO, Language, LanguageDTO, SMTUser, Skill, SkillDTO } from '../model/models';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public readonly CONTROLLERPATH = "admin";

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
  };

  getControllerPath() {
    return `${this.apiService.getServerUrl()}v1/${this.CONTROLLERPATH}`;
  }

  constructor(
    protected injector: Injector,
    protected http: HttpClient,
    protected apiService: ApiService,
    protected classService: ClassService
  ) { }

  // Skills

  getAllSkills():Observable<Skill[]> {
    return this.http.get<Skill[]>(`${this.getControllerPath()}/all-skills`,
    this.httpOptions);
  }

  addSkill(skill: SkillDTO):Observable<SkillDTO> {
    return this.http.post<SkillDTO>(`${this.getControllerPath()}/add-skill`,
    JSON.stringify(skill),
    this.httpOptions);
  }
  
  editSkill(skill:Skill):Observable<Skill> {
    return this.http.put<Skill>(`${this.getControllerPath()}/edit-skill`,
    JSON.stringify(skill),
    this.httpOptions);
  }

  editSelectedSkills(skills: Skill[]):Observable<Skill[]> {
    return this.http.put<Skill[]>(`${this.getControllerPath()}/edit-all-skill`,
    JSON.stringify(skills),
    this.httpOptions);
  }
  
  removeSkill(id: number):Observable<any> {
    return this.http.delete<any>(`${this.getControllerPath()}/remove-skill?id=` + id,
    this.httpOptions);
  }
  // Import / Export 
  getPreviewData(keys: string[]): Observable<Map<string, string>[][]>{
    return this.http.get<Map<string, string>[][]>(
      this.getControllerPath() + "/preview?keys=" + keys,
      this.httpOptions
    );
  }

  getCsvFile(keys: string[]): Observable<Blob>{
    return this.http.get(this.getControllerPath() + "/export?keys=" + keys, {
      responseType: 'blob'
    });
  }

  uploadCsvFile(files: any): Observable<any> {
    let testData:FormData = new FormData();
    testData.append('file', files, files.name);

    return this.http.post<any>(this.getControllerPath() + "/import", testData);
  }

  // Languages
  getAllLanguages():Observable<Language[]> {
    return this.http.get<Language[]>(`${this.getControllerPath()}/all-languages`,
    this.httpOptions);
  }

  addLanguage(language: LanguageDTO):Observable<LanguageDTO> {
    return this.http.post<LanguageDTO>(`${this.getControllerPath()}/add-language`,
    JSON.stringify(language),
    this.httpOptions);
  }
  
  editLanguage(language:Language):Observable<Language> {
    return this.http.put<Language>(`${this.getControllerPath()}/edit-language`,
    JSON.stringify(language),
    this.httpOptions);
  }

  removeLanguage(id: number):Observable<any> {
    return this.http.delete<any>(`${this.getControllerPath()}/remove-language?id=` + id,
    this.httpOptions);
  }

  // Certificates
  getAllCertificates():Observable<Certificate[]> {
    return this.http.get<Certificate[]>(`${this.getControllerPath()}/all-certificates`,
    this.httpOptions);
  }

  addCertificate(certificate: CertificateDTO):Observable<CertificateDTO> {
    return this.http.post<CertificateDTO>(`${this.getControllerPath()}/add-certificate`,
    JSON.stringify(certificate),
    this.httpOptions);
  }
  
  editCertificate(certificate:Certificate):Observable<Certificate> {
    return this.http.put<Certificate>(`${this.getControllerPath()}/edit-certificate`,
    JSON.stringify(certificate),
    this.httpOptions);
  }

  removeCertificate(id: number):Observable<any> {
    return this.http.delete<any>(`${this.getControllerPath()}/remove-certificate?id=` + id,
    this.httpOptions);
  }

  // Users
  getAllUsers():Observable<SMTUser[]> {
    return this.http.get<SMTUser[]>(`${this.getControllerPath()}/all-users`,
    this.httpOptions);
  }

  removeUser(id: number):Observable<any> {
    return this.http.delete<any>(`${this.getControllerPath()}/delete-user?id=` + id,
    this.httpOptions);
  }
}
