<div class="container mt-4 d-flex flex-column justify-content-center align-items-center">
    <div class="w-100">
      <mat-card class="fitler d-flex justify-content-between">
        <div>
          <mat-form-field class="input-field">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search for a record " #input>
          </mat-form-field>
        </div>
        <form  *ngIf="addCertificateMode" class="w-100">
          <div class="d-flex justify-content-end align-items-center">
            <mat-form-field class="input-field w-25 mr-2">
              <mat-label>Name</mat-label>
              <input matInput name="name" #name="ngModel" placeholder="Enter certificate name" [(ngModel)]="newCertificate.name" pattern="[\x00-\x7F]+" required>
              <mat-hint *ngIf="name.errors?.['required'] && (name.dirty || name.touched)" style="color:red;"></mat-hint>
              <mat-hint *ngIf="name.errors?.['pattern']" style="color:red;"></mat-hint>             
            </mat-form-field>
            <mat-form-field class="input-field w-25">       
              <mat-label>Months to xpire</mat-label>       
              <mat-select  (valueChange)="setCertificateExpiration($event)">                
                <mat-option [value]="36" >3 years</mat-option>
                <mat-option [value]="24" >2 years</mat-option>
                <mat-option [value]="12" >1 years</mat-option>
                <mat-option [value]="0" >other (no expiration)</mat-option>
              </mat-select>                           
            </mat-form-field>
            <button mat-raised-button class="btn-action btn-add" style="height:37px" type="submit"
             (click)="addNewCertificate()"
             [disabled]="name.invalid">
              <mat-icon class="btn-certificate-add-ico">add</mat-icon>
              Add to table
            </button>
          </div>
        </form>
      </mat-card>
      <div class="table-container mat-elevation-z8 mt-3">
          <table mat-table [dataSource]="dataSource">
      
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="admin-certificate-table-th"> No. </th>
              <td mat-cell *matCellDef="let certificate">{{certificate.id}}</td>
            </ng-container>          
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="admin-certificate-table-th-name"> Name </th>
              <td mat-cell *matCellDef="let certificate"> 
                <div *ngIf="editMode">
                  <mat-form-field  *ngIf="editMode && selectedCertificate?.id === certificate.id" class="w-90 input-field">
                    <mat-label>Certificate</mat-label>
                    <input matInput placeholder="Change group" [(ngModel)]="selectedCertificate.name" [value]="selectedCertificate.name" pattern="[\x00-\x7F]+" required>                                                      
                  </mat-form-field>                
                </div>
                
                <div *ngIf="selectedCertificate?.id !== certificate.id">
                  {{certificate.name}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="monthsToExpire">
              <th mat-header-cell *matHeaderCellDef class="admin-certificate-table-th"> Months to expire </th>
              <td mat-cell *matCellDef="let certificate"> 
                <div *ngIf="editMode">
                  <mat-form-field  *ngIf="editMode && selectedCertificate?.id === certificate.id" class="w-90 input-field">
                    <mat-label>Months to expire</mat-label>
                    <input matInput placeholder="Change group" [(ngModel)]="selectedCertificate.monthsToExpire" [value]="selectedCertificate.monthsToExpire" pattern="[\x00-\x7F]+" required>                                                      
                  </mat-form-field>                
                </div>
                
                <div *ngIf="selectedCertificate?.id !== certificate.id">
                  {{certificate.monthsToExpire}}
                </div>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="admin-certificate-table-th">
                  Actions
              </th>
              <td mat-cell *matCellDef="let certificate">
                  <div class="d-flex align-middle">
                    <button class="btn-action-white-bg btn-edit" (click)="editCertificate(certificate)" *ngIf="selectedCertificate?.id !== certificate.id">
                      <mat-icon class="edit-ico">edit</mat-icon>
                    </button>
                    <button class="btn-actions">
                      <mat-icon class="delete-ico" (click)="removeCertificate(certificate.id)">delete</mat-icon>
                    </button>                  
                    <button class="btn-action-white-bg" (click)="saveCertificateChanges()" *ngIf="editMode && selectedCertificate?.id === certificate.id">
                      <mat-icon class="save-ico">save</mat-icon>
                    </button>                  
                    <div class="pl-3">
                      <mat-checkbox class="mt-1" color="primary" (change)="isCertificateActive($event,certificate)" [checked]="certificate.isActive">
                        <span  *ngIf="!certificate.isActive" class="certificate-disabled font-italic ml-2">Disabled</span>
                      </mat-checkbox>                                                                                      
                    </div>                  
                  </div>                 
              </td>            
          </ng-container> 
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator class="table-paginator" [pageSizeOptions]="[5, 10, 25,pageFullSizeOption]"                
                         showFirstLastButtons>
          </mat-paginator>
          <div class="action-panel">           
            <button mat-raised-button class="btn-action" (click)="setAddCertificateMode()">
              <mat-icon class="btn-certificate-add-ico btn-ico">add</mat-icon>
              Add certificate
            </button>          
          </div>
      </div>
    </div>
      <div class="spacer"></div>
  </div>