<div class="p-5">
    <h1 mat-dialog-title>Delete {{data.componentName}} ?</h1>
    <div mat-dialog-content class="text-justify">
        Are you sure you want to delete the {{data.componentName}} from the database?
        <br/><br/>
        (This action will completely remove all user records).
    </div>
    <div mat-dialog-actions class="d-flex justify-content-between mt-3">
        <button mat-button (click)="onNoClick()" style="background-color: #3DA080; color: white">No</button>
        <button mat-button [mat-dialog-close]="true" style="background-color:#E77471; color: white">Yes</button>
    </div>
</div>
