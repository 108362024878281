<div class="container">
    <div class="skill mt-4">
        <div clsas="skill-overview">
            <mat-card class="skill-overview-card">
                <mat-card-title class="d-flex justify-content-between align-items-center">
                    <div>Skill overview</div>
                    <div *ngIf="lastUpdateAt != null">
                        <p class="m-0 text-muted">Last update:<span class="ml-2">{{lastUpdateAt | date:"mediumDate"}}</span></p>
                    </div>
                </mat-card-title>                
                <mat-divider></mat-divider>
                <mat-card-content>  

                    <table mat-table  [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="skill-overview-table">

                        <!-- Group Column -->
                        <ng-container matColumnDef="skillGroup">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by group">
                            Group
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.skill.skillGroup}} </td>
                        </ng-container>

                        <!-- Subgroup Column -->
                        <ng-container matColumnDef="skillSubgroup">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by subgroup">
                            Subgroup
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.skill.skillSubgroup}} </td>
                        </ng-container>

                        <!-- SkillName Column -->
                        <ng-container matColumnDef="skillName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by skilName">
                            Skill 
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.skill.skillName}} </td>
                        </ng-container>

                        <!-- SkillLevel Column -->
                        <ng-container matColumnDef="skillLevel">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by skillLevel">
                                Level
                            </th>
                            <td mat-cell *matCellDef="let element"> <mat-icon class="star" *ngFor="let i of Arr(element.level).fill(1)">star</mat-icon> </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef >
                                Actions
                            </th>
                            <td mat-cell *matCellDef="let element"> 
                                <button class="btn-actions">
                                    <mat-icon class="edit-ico" (click)="editSkill(element)">edit</mat-icon>
                                </button>
                                <button class="btn-actions">
                                    <mat-icon class="delete-ico" (click)="removeSkill(element)">delete</mat-icon>
                                </button>
                            </td>            
                        </ng-container>                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-divider></mat-divider>    

                    <mat-card-actions [class.skill-overview-add]="!addSkillMode && !editSkillMode">
                        <button *ngIf="!addSkillMode && !editSkillMode" class="btn btn-skill-add mt-3" mat-raised-button (click)="addSkill()"><div class="btn-skill-add-inner"><mat-icon class="btn-skill-add-ico">add</mat-icon>Add skill</div></button>                                                
                        <div class="skill-add-form" *ngIf="addSkillMode && !editSkillMode">
                            <p class="skill-add-form-title ml-2">Add new skill</p>
                            <div class="skill-add-form-properties">
                                <table class="skill-add-form-table">
                                    <tr>
                                        <td *ngIf="!selectedGroup">Select group</td><td *ngIf="selectedGroup">{{selectedGroup}}</td>
                                        <td *ngIf="!selectedSubgroup">Select subgroup</td><td *ngIf="selectedSubgroup">{{selectedSubgroup}}</td>
                                        <td *ngIf="!selectedSkill">Select skill</td><td *ngIf="selectedSkill">{{selectedSkill.skillName}}</td>                                                                        
                                        <td>
                                            <mat-icon  [class.stars-selected]="level <= userSkillLevel"
                                                [matTooltip]="setSkillLevelLegend(level)"
                                                *ngFor="let level of levelValueList"
                                                [class.starts-hovered-after-selected]="level <= hoveredStars"
                                                (mouseleave)="unhoverSkillLevel()"
                                                (mouseover)="hoverSkillLevel(level)"
                                                (click)="setUserSkillLevel(level)" >
                                                {{level <= userSkillLevel ? 'start' : 'star_borderd'}}
                                            </mat-icon>
                                        </td>
                                        <td>
                                            <button mat-raised-button class="btn-skill-add-new"  
                                                [disabled]="!selectedGroup || !selectedSubgroup || !selectedSkill || !userSkillLevel"
                                                (click)="addNewSkill()">
                                                <div class="btn-skill-add-inner">
                                                    <mat-icon class="btn-skill-add-ico">add</mat-icon>
                                                    Add skill
                                                </div>
                                            </button>                                        
                                        </td>                                    
                                    </tr>
                                </table>
                            </div>
                        </div>  
                        <div class="skill-edit-form" *ngIf="editSkillMode">
                            <p class="skill-edit-form-title ml-2">Edit new skill</p>
                            <div class="skill-edit-form-properties">
                                <table class="skill-edit-form-table">
                                    <tr>
                                        <td *ngIf="!selectedGroup">Select group</td><td *ngIf="selectedGroup">{{selectedGroup}}</td>
                                        <td *ngIf="!selectedSubgroup">Select subgroup</td><td *ngIf="selectedSubgroup">{{selectedSubgroup}}</td>
                                        <td *ngIf="!selectedSkill">Select skill</td><td *ngIf="selectedSkill">{{selectedSkill.skillName}}</td>                                        
                                        <td >
                                            <mat-icon [class.stars-selected]="level <= userSkillLevel"
                                                [matTooltip]="setSkillLevelLegend(level)"                                                
                                                *ngFor="let level of levelValueList"
                                                [class.starts-hovered-after-selected]="level <= hoveredStars"
                                                (mouseleave)="unhoverSkillLevel()" (mouseover)="hoverSkillLevel(level)"
                                                (click)="setUserSkillLevel(level)">
                                                {{level <= userSkillLevel ? 'start' : 'star_borderd'}}
                                            </mat-icon>
                                        </td>
                                        <td>                                                                                    
                                            <button class="btn-actions btn-save-changes" 
                                                [disabled]="!selectedGroup || !selectedSubgroup || !selectedSkill || !userSkillLevel"
                                                (click)="saveChanges()">
                                                <mat-icon class="save-ico">
                                                    save
                                                </mat-icon>
                                            </button>                                            
                                        </td>                                    
                                    </tr>
                                </table>
                            </div>
                        </div>  
                    </mat-card-actions>                
                </mat-card-content>                
            </mat-card>
            <mat-card class="infobox-skill-request" *ngIf="addSkillMode || editSkillMode">
                <div class="infobox-title">
                    <h4 class="infobox-title-text">* Are you missing a skill in the list? Send us a request for a new skill addition.</h4>
                </div>
                <div class="infobox-actions">
                    <a mat-raised-button class="infobox-btn btn-report-email" [href]="reportEmail">
                        <mat-icon>email</mat-icon>
                        Request skill
                    </a>
                    <button mat-raised-button class="infobox-btn btn-refresh-filter" (click)="refreshFilter()">
                        <mat-icon>filter_list</mat-icon>
                        Refresh filter
                    </button>
                </div>
            </mat-card>
        </div>
        <div>
            <div class="w-100 mt-3 d-flex" [class.skill-search]="!isIdenticalSubgroup" *ngIf="addSkillMode || editSkillMode">
                <mat-card class="skill-search-card" [class.skill-search-group]="isIdenticalSubgroup">
                    <app-search-field [searchFieldLabel]="searchFieldLabels[0]" [Control]="groupSearchFieldControl" (searchEvent)="setSearchFieldValueGroup($event)"></app-search-field>
                    <mat-action-list class="search-list-items">
                        <button [appScrollIntoView]="skillGroup === selectedGroup"  [class.selected]="skillGroup === selectedGroup" mat-list-item *ngFor="let skillGroup of skillGroupList | searchFilter:groupSearchFieldValue:'group'" (click)="selectGroup(skillGroup)">{{skillGroup}}</button>
                      </mat-action-list>
                </mat-card>
                <mat-card class="skill-search-subgroup skill-search-card" *ngIf="!isIdenticalSubgroup">
                    <app-search-field [searchFieldLabel]="searchFieldLabels[1]" [Control]="subgroupSearchFieldControl" (searchEvent)="setSearchFieldValueSubgroup($event)"></app-search-field>
                    <mat-action-list class="search-list-items">
                        <button [appScrollIntoView]="skillSubgroup === selectedSubgroup" [class.selected]="skillSubgroup === selectedSubgroup" mat-list-item *ngFor="let skillSubgroup of skillSubgroupList | searchFilter:subgroupSearchFieldValue:'subgroup'" (click)="selectSubgroup(skillSubgroup)">{{skillSubgroup}}</button>
                    </mat-action-list>
                </mat-card>
                <mat-card class="skill-search-name skill-search-card">
                    <app-search-field [searchFieldLabel]="searchFieldLabels[2]" [Control]="skillSearchFieldControl" (searchEvent)="setSearchFieldValueSkill($event)"></app-search-field>
                    <mat-action-list class="search-list-items">
                        <button [class.disabled-item]="skillInList(skill)" [matTooltip]="skill.skillName.length > 33 ? skill.skillName : ''" [disabled]="skillInList(skill)" [class.selected]="skill.id === selectedSkill?.id" mat-list-item *ngFor="let skill of skillsList | searchFilter:skillSearchFieldValue:'skill'" (click)="selectSkill(skill)"><span class="truncate-text">{{skill.skillName}}</span></button>
                    </mat-action-list>
                </mat-card>
            </div>
            <mat-card class="mt-3" *ngIf="(addSkillMode || editSkillMode) && (suggestedSkillList && suggestedSkillList.length > 0)">
                <h4 class="pb-2 infobox-title-text">* Other people who selected the same skills as you also selected following skills. Click on a suggested skill to add it.</h4>
                <button *ngFor="let skill of suggestedSkillList" mat-raised-button class="m-1" (click)="selectRecommendedSkill(skill)" matTooltip="{{skill.skillName}}">{{skill.skillName}}</button>
            </mat-card>
        </div>
    </div>
    <div class="spacer"></div>
</div>