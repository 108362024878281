import { Injectable, Injector } from '@angular/core';
import { ApiService, ClassService } from "@profect/v7-core-lib";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Role, SMTUser } from '../model/models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public readonly CONTROLLERPATH = "user";

  httpOptions = {
    headers: new HttpHeaders(
      { "Content-Type": "application/json"}
    )
  };



  getControllerPath() {
    return `${this.apiService.getServerUrl()}v1/${this.CONTROLLERPATH}`;
  }

  constructor(
    protected injector: Injector,
    protected http: HttpClient,
    protected apiService: ApiService,
    protected classService: ClassService
  ) { }

  getCurrentUser(): Observable<SMTUser>{
    return this.http.get<SMTUser>(
      this.apiService.getServerUrl() + "v1/get-current-user",
      this.httpOptions
    );
  }

  getUserRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.getControllerPath()}/user-roles`,
    this.httpOptions
    );
  }

  getUserTimestamp(): Observable<Date> {
    return this.http.get<Date>(`${this.getControllerPath()}/user-timestamp`,
    this.httpOptions
    );
  }

  logoutUser() {
    return this.http.get(`${this.apiService.getServerUrl()}logout`,
    this.httpOptions
    );
  }
}
