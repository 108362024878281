import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SMTUserLanguage } from 'src/app/model/models';

@Component({
  selector: 'app-user-data-table',
  templateUrl: './user-data-table.component.html',
  styleUrls: ['./user-data-table.component.scss']
})
export class UserDataTableComponent implements OnInit, OnChanges {

  @Input() dataSource: any;
  @Input() map: any;

  @Output() deleteEvent = new EventEmitter<number>();
  @Output() editEvent = new EventEmitter<any>();
  @Input() displayedColumns: string[] = ['language','level','actions'];
  nestedProps: any;

  constructor() {
    
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  getItemId(id: number) {
    this.deleteEvent.emit(id);
  }

  setEditMode(element: any) {
    this.editEvent.emit(element);
  }

  displayLevel(level: number) {
    for(let [key, value] of this.map.entries() ) {
      if(value === level) {
        return key;
      }
    }
    return undefined;
  }

}
