import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role, SMTUser } from 'src/app/model/models';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: SMTUser;
  userRoles: Role[] = [];

  constructor (
    private userService: UserService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getUserRoles();
    this.getCurrentUrl();
  }

  setMenuWidth(btn, menu) {
    menu.style.width = `${btn._elementRef.nativeElement.offsetWidth}px`;
  }

  getUserRoles() {
    this.userService.getUserRoles().subscribe(
      roles => {
        this.userRoles = roles;
      }
    );
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe(
      user => this.currentUser = user
    );
  }

  isAdmin(): boolean {
    let index = this.userRoles.findIndex( r => r.name == "ADMIN");

    if (index != -1)
      return true;

    return false;
  }

  getCurrentUrl() {
    return this.route.url;
  }

}
