import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueArr'
})
export class UniqueArrPipe implements PipeTransform {

  transform(collection,field) {
    return this.getUniqueArr(collection,field);
  }

  getUniqueArr(collection,property:string) {
    console.log(property);
    const uniqueArr = [];
      const unique = collection.filter(element => {
      const isDuplicate = uniqueArr.includes(element[property]);
        if (!isDuplicate) {
          uniqueArr.push(element[property]);
          return true;
        }
        return false;
      });
      return unique; 
  }
  
}
