<app-header></app-header>
<div class="container">
  <mat-tab-group
    dynamicHeight
    class="mt-4"
    (selectedTabChange)="onTabChanged($event)"
  >
    <mat-tab label="Skills">
      <app-skill-overview></app-skill-overview>
    </mat-tab>
    <mat-tab label="Languages">
      <app-language-overview></app-language-overview>
    </mat-tab>
    <mat-tab label="Certificates">
      <app-certifications-overview></app-certifications-overview>
    </mat-tab>
    <mat-tab label="Find By Skill">
      <app-find-skill-overview></app-find-skill-overview>
    </mat-tab>
  </mat-tab-group>
</div>
