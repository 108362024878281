import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Language, LanguageDTO } from 'src/app/model/models';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from 'src/app/services/language.service';
import { ConfirmationDialog } from '../confirmation-dialog';

@Component({
  selector: 'app-admin-language-overview',
  templateUrl: './admin-language-overview.component.html',
  styleUrls: ['./admin-language-overview.component.scss']
})
export class AdminLanguageOverviewComponent implements OnInit {


 // Mat table vars
 @Input() dataSource = new MatTableDataSource<Language>();
 displayedColumns: string[] = ['id','name','actions'];
 selection = new SelectionModel<Language>(true, []);

 @ViewChild(MatPaginator) paginator: MatPaginator;
 @Output() setPaginatorEvent = new EventEmitter(); 
 // Mode vars
 editMode: boolean = false;
 addLanguageMode: boolean = false;

 // Language vars
 @Input() allLanguages: Language[];
 selectedLanguage: Language;
 selectedLanguageWithoutChanges: Language;
 newLanguage: LanguageDTO = {
   name: "",
   isActive: true
 }

 

 // Other vars
 @Input() pageFullSizeOption: number;

 constructor (
   private adminPageService: AdminService,
   public dialog: MatDialog,
   private _snackBar: MatSnackBar
 ) { 
  
 }

 ngOnInit(): void {  
  this.getAllLanguages();
 }

 

 getAllLanguages() {
   this.adminPageService.getAllLanguages().subscribe(
     languages => {        
       languages.sort((a,b) => a.id - b.id);
       this.allLanguages = languages;
       this.dataSource = new MatTableDataSource<Language>(this.allLanguages);
       this.dataSource.paginator = this.paginator;  
       this.pageFullSizeOption = this.allLanguages.length;
       this.setPaginator();
     }
   );
 }
   
 setPaginator() {
  this.setPaginatorEvent.emit(this.paginator);
 }

 editLanguage(language: Language) {
   if(this.selectedLanguage) {
     for(let property in this.selectedLanguage) {
       if(!this.selectedLanguage[property]) {
         this.selectedLanguage[property] = this.selectedLanguageWithoutChanges[property];
       }
     }
   }
   this.selectedLanguageWithoutChanges = {...language};
   this.selectedLanguage = language;
   this.editMode = true;
   
 }


 saveLanguageChanges() {
   if(this.selectedLanguage.name) {
     this.trimObjectFields(this.selectedLanguage);
     this.adminPageService.editLanguage(this.selectedLanguage).subscribe();
     this.selectedLanguage = undefined;
     this.editMode = false;
   } else {
     this.openSnackBar("Don't leave empty fields!","close");
   }
 }

 openDialog(id: number): void {
  const dialogRef = this.dialog.open(ConfirmationDialog, {
    width: '320px',
    data: {componentName: 'language'}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');     
    if(result) {
      this.adminPageService.removeLanguage(id).subscribe(
        data => {
          this.getAllLanguages();
        }
      ); 
    }   
  });
}

 removeLanguage(id: number) {
  this.openDialog(id);
 }

 applyFilter(event: Event) {
   const filterValue = (event.target as HTMLInputElement).value;
   this.dataSource.filter = filterValue.trim().toLowerCase();
 }

 setAddLanguageMode() {
   this.addLanguageMode = !this.addLanguageMode;
 }

 addNewLanguage() {
   if(this.allLanguages.some(item => item.name.trim() === this.newLanguage.name.trim())) {
     this.openSnackBar(`Language: "${this.newLanguage.name}" already exists!`,'Close');
   } else {
     
     this.trimObjectFields(this.newLanguage);

     this.adminPageService.addLanguage(this.newLanguage).subscribe(
       data => {
         this.getAllLanguages();
         this.dataSource._updateChangeSubscription();
         this.openSnackBar(`Language "${this.newLanguage.name}" was added to list`,"Close");
       }
     );
   }    
 }

 isLanguageActive(event,language) {
   language.isActive = event.checked;
   this.adminPageService.editLanguage(language).subscribe();
   this.dataSource._updateChangeSubscription();
 }

 openSnackBar(message: string, action: string) {
   this._snackBar.open(message, action);
 }

 trimObjectFields(object: any) {
   for(let property in object) {
     if(typeof object[property] == "string") {
       object[property] = object[property].trim();              
     }
   }
 }

}