import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@profect/v7-core-lib';
import { SMTUserLanguage, UserLanguageDTO } from '../model/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserLanguageService {

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
  };
  

  constructor(
    private http:HttpClient,
    protected apiService: ApiService
  ) { }

  getControllerPath() {
    return `${this.apiService.getServerUrl()}v1/user-language/`;
  }

  getCurrentUserLanguages(): Observable<SMTUserLanguage[]> {
    return this.http.get<SMTUserLanguage[]>(`${this.getControllerPath()}current-user-languages`,
    this.httpOptions);
  }

  addLanguage(userLanguage: UserLanguageDTO): Observable<SMTUserLanguage> {
    return this.http.post<SMTUserLanguage>(`${this.getControllerPath()}add-language`,
    JSON.stringify(userLanguage),
    this.httpOptions)
  }

  updateLanguage(userLanguage: SMTUserLanguage):Observable<SMTUserLanguage> {
    return this.http.put<SMTUserLanguage>(`${this.getControllerPath()}update-language`,
    JSON.stringify(userLanguage),
    this.httpOptions);
  }

  removeLanguage(id: number):Observable<any> {
    return this.http.delete<any>(`${this.getControllerPath()}remove-language?id=` + id,
    this.httpOptions);
  }
}
