import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Skill, SkillDTO } from 'src/app/model/models';
import { SkillService } from 'src/app/services/skill.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialog } from '../confirmation-dialog';
@Component({
  selector: 'app-admin-skill-overview',
  templateUrl: './admin-skill-overview.component.html',
  styleUrls: ['./admin-skill-overview.component.scss']
})
export class AdminSkillOverviewComponent implements OnInit {

 // Mat table vars
 @Input() dataSource = new MatTableDataSource<Skill>();
 displayedColumns: string[] = ['select','id', 'skillGroup', 'skillSubgroup', 'skillName','actions'];
 selection = new SelectionModel<Skill>(true, []);

 @ViewChild(MatPaginator) paginator: MatPaginator;
 @Output() setPaginatorEvent = new EventEmitter(); 
 // Mode vars
 editMode: boolean = false;
 addSkillMode: boolean = false;
 editSelectedSkillsdMode: boolean = false;

 // SKill vars
 @Input() allSkills: Skill[];
 selectedSkill: Skill;
 selectedSkillWithoutChanges: Skill;
 newSkill: SkillDTO = {
   name: "",
   subGroup: "",
   group: "",
   isActive: true
 }

 skillGroup: string;
 skillName: string;
 skillSubgroup: string;
 

 // Other vars
 @Input() pageFullSizeOption: number;

 constructor (
   private skillService: SkillService,
   private adminPageService: AdminService,
   public dialog: MatDialog,
   private _snackBar: MatSnackBar
 ) { 
  
 }

 ngOnInit(): void {  
  this.getAllSkills();
 }

 

 getAllSkills() {
   this.adminPageService.getAllSkills().subscribe(
     skills => {        
       skills.sort((a,b) => a.id - b.id);
       this.allSkills = skills;
       this.dataSource = new MatTableDataSource<Skill>(this.allSkills);
       this.dataSource.paginator = this.paginator;  
       this.pageFullSizeOption = this.allSkills.length;
       this.setPaginator();
     }
   );
 }
   
 setPaginator() {
  this.setPaginatorEvent.emit(this.paginator);
 }

 editSkill(skill: Skill) {
   if(this.selectedSkill) {
     for(let property in this.selectedSkill) {
       if(!this.selectedSkill[property]) {
         this.selectedSkill[property] = this.selectedSkillWithoutChanges[property];
       }
     }
   }
   this.selectedSkillWithoutChanges = {...skill};
   this.selectedSkill = skill;
   this.editMode = true;
   
 }

 editSelected() {
   this.editSelectedSkillsdMode = !this.editSelectedSkillsdMode;
   this.addSkillMode = false;  
 }

 saveSkillChanges() {
   if(this.selectedSkill.skillGroup && this.selectedSkill.skillSubgroup && this.selectedSkill.skillName) {
     this.trimObjectFields(this.selectedSkill);
     this.adminPageService.editSkill(this.selectedSkill).subscribe();
     this.selectedSkill = undefined;
     this.editMode = false;
   } else {
     this.openSnackBar("Don't leave empty fields!","close");
   }
 }

 saveSelectedSkillsChanges() {
   this.selection.selected.map(item => {
     if(this.skillGroup) {
       item.skillGroup = this.skillGroup;
     }
     if(this.skillSubgroup) {
       item.skillSubgroup = this.skillSubgroup;
     }
     if(this.skillName) {
       item.skillName = this.skillName;
     }
   });

   this.adminPageService.editSelectedSkills(this.selection.selected).subscribe(
     data => {
       this.getAllSkills();
     }
   );
   this.dataSource._updateChangeSubscription();

 }

 openDialog(id: number): void {
  const dialogRef = this.dialog.open(ConfirmationDialog, {
    width: '320px',
    data: {componentName: 'skill'}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');     
    if(result) {
      this.adminPageService.removeSkill(id).subscribe(
        data => {
          this.getAllSkills();
        }
      ); 
    }   
  });
}

 removeSkill(id: number) {
  this.openDialog(id);
 }

 isAllSelected() {
   const numSelected = this.selection.selected.length;
   const numRows = this.dataSource.data.length;
   return numSelected === numRows;
 }

 toggleAllRows() {        
   if (this.isAllSelected()) {
     this.selection.clear();
     return;
   }

   this.selection.select(...this.dataSource.data);
 }

 checkboxLabel(row?: Skill): string {
   if (!row) {
     return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
   }
   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
 }

 applyFilter(event: Event) {
   const filterValue = (event.target as HTMLInputElement).value;
   this.dataSource.filter = filterValue.trim().toLowerCase();
 }

 setAddSkillMode() {
   this.addSkillMode = !this.addSkillMode;
   this.editSelectedSkillsdMode = false;
 }

 addNewSkill() {
   if(this.allSkills.some(item => item.skillName === this.newSkill.name)) {
     this.openSnackBar(`SKill: "${this.newSkill.name}" already exists!`,'Close');
   } else {
     
     this.trimObjectFields(this.newSkill);

     this.adminPageService.addSkill(this.newSkill).subscribe(
       data => {
         this.getAllSkills();
         this.dataSource._updateChangeSubscription();
         this.openSnackBar(`Skill "${this.newSkill.name}" was added to list`,"Close");
       }
     );
   }    
 }

 isSkillActive(event,skill) {
   skill.isActive = event.checked;
   this.adminPageService.editSkill(skill).subscribe();
   this.dataSource._updateChangeSubscription();
 }

 disableSelected() {
   this.selection.selected.map(item => item.isActive = false);
   this.adminPageService.editSelectedSkills(this.selection.selected).subscribe();
 }

 openSnackBar(message: string, action: string) {
   this._snackBar.open(message, action);
 }

 trimObjectFields(object: any) {
   for(let property in object) {
     if(typeof object[property] == "string") {
       object[property] = object[property].trim();              
     }
   }
 }

}
