import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {

  @Input() Control: FormControl = new FormControl();
  @Input() searchValue: string;
  @Input() inputLabel: string;
  
  @Input() searchFieldLabel: string;

  @Output() searchEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {

  }

  getSearchFieldValue(fieldValue: string) {
    this.searchEvent.emit(fieldValue);
  }



}
