<div class="container">
    <div class="certification mt-4">
        <div class="certification-overview">
            <mat-card class="certification-overview-card">
                <mat-card-title class="d-flex justify-content-between align-items-center">
                    <div>{{viewTitle}}</div>
                </mat-card-title>                
                <mat-divider></mat-divider>
                <mat-card-content>  
                                          
                    <table mat-table [dataSource]="userCertificates" class="table w-100 mb-2">
                                                                                                                                            
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef class="certificate-name"> Name </th>
                          <td mat-cell *matCellDef="let element" class="certificate-name"> {{element?.certificate?.name}} </td>
                        </ng-container>                                            

                        <ng-container matColumnDef="issueName">
                          <th mat-header-cell *matHeaderCellDef>Issuer Name</th>
                          <td mat-cell *matCellDef="let element"> {{element?.issuerName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="issueDate">
                          <th mat-header-cell *matHeaderCellDef>Issued Date</th>
                          <td mat-cell *matCellDef="let element"> {{element?.issueDate | date: 'shortDate'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="monthsToExpire">
                          <th mat-header-cell *matHeaderCellDef>Valid until</th>                                                    
                          <td mat-cell *matCellDef="let element">
                            <section *ngIf="element.certificate.monthsToExpire == 0"><mat-icon>all_inclusive</mat-icon></section>
                            <section *ngIf="element.certificate.monthsToExpire != 0">{{(getUntilValid(element)| date: 'shortDate')}}</section>
                          </td>                                                                                  
                        </ng-container>

                        <ng-container matColumnDef="isValid">
                          <th mat-header-cell *matHeaderCellDef>Validity</th>                          
                          <td mat-cell *matCellDef="let element"> 
                            <section *ngIf="element.isValid" class="example-section">                              
                              <span class="cert-valid mb-1">Valid</span>
                            </section>
                            <section *ngIf="!element.isValid">
                              <span class="cert-expired mb-1"><b>Expired</b></span>
                            </section>                                                        
                          </td>
                        </ng-container>
                                            
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef >
                              Actions
                          </th>
                          <td mat-cell *matCellDef="let element"> 
                              <button class="btn-actions" (click)="removeCertificate(element.id)">
                                  <mat-icon class="delete-ico">delete</mat-icon>
                              </button>
                          </td>            
                        </ng-container>  
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>

                </mat-card-content>   
            </mat-card>
        </div>
          <mat-card class="infobox-certificate-request mt-3 d-flex justify-content-between">
            <div class="infobox-title d-flex align-items-center">
                <h4 class="infobox-title-text">* Are you missing a certificate in the list? Send us a request for a new certificate addition.</h4>
            </div>
            <div class="infobox-actions">
                <a mat-raised-button class="infobox-btn btn-report-email" [href]="reportEmail">
                    <mat-icon>email</mat-icon>
                    Request certificate
                </a>                
            </div>
        </mat-card>
        <div class="add-panel d-flex justify-content-between mt-3">
          <mat-card class="add-certificate-card">
            <mat-card-title>Add certificate</mat-card-title>            
            <mat-divider></mat-divider>
            <mat-card-content class="add-certificate-card-content ">
              <div class="add-certificate-content">
                <div>
                  <mat-form-field class="w-100 readonly-field">                      
                    <mat-label>Certificate</mat-label>
                    <input matInput readonly [value]="selectedCertificate ? selectedCertificate?.name : 'Select certificate from list'">                            
                  </mat-form-field>
                </div>
                <div>                  
                  <mat-form-field class="w-100">                      
                    <mat-label>Issuer Name</mat-label>
                    <input type="text" matInput [(ngModel)]="certificateIssuerName" matTooltip="Please provide the name of the company, institution or authority which issued the certification to you. This is important to ensure validity of the certification and serves as quality assurance.">                          
                  </mat-form-field>
                </div> 
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Issued date</mat-label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]="issueDate" [max]="maxDateToPick" matTooltip="Some certifications are valid only for certain period of time. This information is recorded in Skillm8 backend for each certificate in our database. When your certification expires we will let you know via email and you will be prompted to renew your certification. Expired certifications will remain recorded in your profile and you will need to add new ones with a new issued date if you wish to renew it. ">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div class="d-flex justify-content-end">
                  <button [disabled]="!selectedCertificate || !certificateIssuerName || !issueDate" class="btn btn-action mt-3" mat-raised-button (click)="addCertificate()"><div class="btn-cert-action-inner"><mat-icon class="btn-lang-add-ico">add</mat-icon>Add certificate</div></button>                
                </div>    
              </div>                                   
            </mat-card-content>
          </mat-card>
          <div class="aside-panel">
            <app-search-container class="certificate-search-card" 
                    [searchList]="certificates"                    
                    [objectName]="objectName"
                    [userDataList]="userCertificates"
                    (selectItemEvent)="selectCertification($event)"
                    [property]="property"
                    [selectedItem]="selectedCertificate">
            </app-search-container>  
          </div>
        </div>
    </div>
    <div class="spacer"></div>
</div>

