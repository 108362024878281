import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SMTUser} from 'src/app/model/models';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '@profect/v7-core-lib';
import { ConfirmationDialog } from '../confirmation-dialog';


@Component({
  selector: 'app-admin-users-overview',
  templateUrl: './admin-users-overview.component.html',
  styleUrls: ['./admin-users-overview.component.scss']
})
export class AdminUsersOverviewComponent implements OnInit {

 // Mat table vars
 @Input() dataSource = new MatTableDataSource<SMTUser>();
 displayedColumns: string[] = ['id','displayName','email','actions'];

 @ViewChild(MatPaginator) paginator: MatPaginator;
 @Output() setPaginatorEvent = new EventEmitter(); 
 allUsers: SMTUser[];
 isUserDeletionConfirmed: boolean;
 // Mode vars


 // Certificate vars


 

 // Other vars
 @Input() pageFullSizeOption: number;

 constructor (
   private adminPageService: AdminService,
   public dialog: MatDialog,
   private _snackBar: MatSnackBar
 ) { 
  
 }

 ngOnInit(): void {  
  this.getAllUsers();
 }

 

 getAllUsers() {
    this.adminPageService.getAllUsers().subscribe(
      users => {                
        users.sort((a,b) => a.id - b.id);
        this.allUsers = users;
        this.dataSource = new MatTableDataSource<SMTUser>(this.allUsers);
        this.dataSource.paginator = this.paginator;  
        this.pageFullSizeOption = this.allUsers.length;
        this.setPaginator();
      }
    );
 }
   
 setPaginator() {
    this.setPaginatorEvent.emit(this.paginator);
 }

 applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
 }

openDialog(id: number): void {
  const dialogRef = this.dialog.open(ConfirmationDialog, {
    width: '320px',
    data: {componentName: 'user'}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');     
    if(result) {
      this.adminPageService.removeUser(id).subscribe(
        data => {
          this.getAllUsers();
        }
      ); 
    }   
  });
}

 removeUser(id: number) {
    this.openDialog(id);       
 }

}
