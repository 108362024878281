import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@profect/v7-core-lib';
import { Observable } from 'rxjs';
import { Certificate } from '../model/models';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
  };

  constructor(
    private http:HttpClient,
    protected apiService: ApiService
  ) { }
  
  getControllerPath() {
    return `${this.apiService.getServerUrl()}v1/certificate/`;
  }
  
  getCertificates(): Observable<Certificate[]> {
    return this.http.get<Certificate[]>(`${this.getControllerPath()}all`,
    this.httpOptions);
  }

}
