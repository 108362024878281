import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag} from '@angular/cdk/drag-drop';
import { AdminService } from 'src/app/services/admin.service';
import { saveAs } from 'file-saver';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

interface ExportPreset {
  name: string;
  columnNames: string[];
  fileName: string;
  FirstKeys: string[];
  SecondKeys: string[];
  CsvKeys: string[];
}

interface FileCsv {
  name: string;
  isLoaded: boolean;
}

const UserKeys =  ['User Id', 'User Display name', 'User Firstname', 'User Surname', 'User Username', 'User E-mail', 'User NetSuiteId'];
const SkillKeys = ['Skill Id', 'Skill Name', 'Skill Group', 'Skill Subgroup', 'Skill Level', 'Skill Status'];
const LanguageKeys = ['Language Id', 'Language Name', 'Language Level', 'Language Level(CEFR)'];
const CertificateKeys = ['Certificate Id', 'Certificate Name', 'Certificate Issuer', 'Certificate Issue Date', 'Certificate Status'];

const Presets: ExportPreset[] = [
  {name: "Export User table", columnNames: ["User keys:", "Skill keys:"], fileName: "Users", FirstKeys: UserKeys, SecondKeys: [], CsvKeys: UserKeys},
  {name: "Export Skills table", columnNames: ["User keys:", "Skill keys:"], fileName: "Skills", FirstKeys: [], SecondKeys: SkillKeys, CsvKeys: ['Skill Id', 'Skill Group', 'Skill Subgroup', 'Skill Name']},
  {name: "Export Certifications", columnNames: ["User keys:", "Certifications keys:"], fileName: "Certifications", FirstKeys: [], SecondKeys: CertificateKeys, CsvKeys: ['Certificate Id', 'Certificate Name']},
  {name: "Export Skills for Users", columnNames: ["User keys:", "Skill keys:"], fileName: "Users-Skills", FirstKeys: UserKeys, SecondKeys: SkillKeys, CsvKeys: ['User Id','User E-mail','User Display name','User NetSuiteId', ...SkillKeys]},
  {name: "Export Languages for Users", columnNames: ["User keys:", "Language keys:"], fileName: "Users-Languages", FirstKeys: UserKeys, SecondKeys: LanguageKeys, CsvKeys: ['User Id', 'User E-mail', 'User Display name', 'User NetSuiteId', ...LanguageKeys]},
  {name: "Export Certifications for Users", columnNames: ["User keys:", "Certifications keys:"], fileName: "Users-Certifications", FirstKeys: UserKeys, SecondKeys: CertificateKeys, CsvKeys: ['User Id', 'User E-mail', 'User Display name', 'User NetSuiteId', ...CertificateKeys]}
];

@Component({
  selector: 'app-admin-popup',
  templateUrl: './admin-popup.component.html',
  styleUrls: ['./admin-popup.component.scss']
})

export class AdminPopupComponent implements OnInit {

  importPanelOpenState = true;
  exportPanelOpenState = false;
  advancedPanelOpenState = false;
  isAdvanceChecked = false;
  hasHighlightFirstKeys = false;
  hasHighlightSecondKeys = false;
  hasHighloghtCSVKeys = false;

  error: string;
  dragAreaClass: string;
  draggedFiles: any;

  presets: ExportPreset[] = Presets;
  currentPreset: ExportPreset;

  csvFiles: FileCsv[] = [];

  dataSource : any[] = [];
  userKeys = [];
  skillKeys = [];
  csvKeys = [];

  constructor(public adminService: AdminService, 
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentPreset = this.presets[0];
    this.onPresetChanged(this.currentPreset);
    this.advancedPanelOpenState = this.isAdvanceChecked;
  }

  // File Export
  onPresetChanged(preset: ExportPreset) {
    this.userKeys = [];
    this.skillKeys = [];
    this.csvKeys = [];
    this.userKeys = [];
    this.skillKeys = [];
    this.csvKeys = [...preset.CsvKeys];
    this.currentPreset = preset;

    this.adminService.getPreviewData(this.csvKeys).subscribe(data => this.dataSource = data);
  }

  onAdvancedCheck(isChecked: boolean) {
    this.isAdvanceChecked = isChecked;
    this.advancedPanelOpenState = isChecked;
    if (!isChecked) {
      this.onPresetChanged(this.currentPreset);
    }
  }

  onDownloadClick() {
    this.adminService.getCsvFile(this.csvKeys).subscribe(blob => saveAs(blob, "Export-" + this.currentPreset.fileName + "-" + new Date().toISOString().split('T')[0] + ".csv"));
  }

  // File Upload
  onDragOver(event) {
    event.preventDefault();
  }

  onDropSuccess(event) {
    event.preventDefault();

    this.onFileChange(event.dataTransfer.files);
  }

  onChange(event) {
    this.onFileChange(event.target.files);
  }

  onFileChange(files) {
    let file = {name: files.item(0).name, isLoaded: false} as FileCsv;
    this.csvFiles.push(file);
    this.adminService.uploadCsvFile(files.item(0)).subscribe(response => 
      {
        file.isLoaded = true; 
        this.openSnackBar("Data was successfully recorded")
      });
  }

  // Preview table
  getCellData(column: string, data: any) : string {
    if (this.dataSource === undefined)
      return ""

    return data[column];
  }

  // Drang and drop keys for export
  dragFromUserOrSkillStarted(event: any) {
    this.hasHighloghtCSVKeys = true;
  }

  dragStarted(event: any) {
      if (this.currentPreset.FirstKeys.includes(event.source.data)) {
        this.hasHighlightFirstKeys = true;
      }
  
      if (this.currentPreset.SecondKeys.includes(event.source.data)) {
        this.hasHighlightSecondKeys = true;
      }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.isAdvanceChecked = true;
    this.hasHighlightFirstKeys = false;
    this.hasHighlightSecondKeys = false;
    this.hasHighloghtCSVKeys = false;

    if (!event.isPointerOverContainer) {

      if (this.currentPreset.FirstKeys.includes(event.item.data)) {
        let index = event.previousContainer.data.indexOf(event.item.data);
        event.previousContainer.data.splice(index, 1);
        this.pushAtPosition(this.userKeys, event.item.data, this.currentPreset.FirstKeys.indexOf(event.item.data));
      }

      if (this.currentPreset.SecondKeys.includes(event.item.data)) {
        let index = event.previousContainer.data.indexOf(event.item.data);
        event.previousContainer.data.splice(index, 1);
        this.pushAtPosition(this.skillKeys, event.item.data, this.currentPreset.SecondKeys.indexOf(event.item.data));
      }
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.adminService.getPreviewData(this.csvKeys).subscribe(data => this.dataSource = data);
    }
  }

  pushAtPosition(array: string[], item: string, index: number) {
    array.splice(index, 0, item);
  }

  noReturnPredicate() {
    return false;
  }

  // Expansion panels

  onImportClick() {
    this.exportPanelOpenState = false;

    this.importPanelOpenState = true;
  }

  onExportClick() {
    this.importPanelOpenState = false;

    this.exportPanelOpenState = true;
  }

  // Snack bar

  openSnackBar(message: string) {
    const duration = 3000;
    this._snackBar.open(message, 'Close', { duration });
  }
}
