import { V7Configuration } from '@profect/v7-core-lib';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: V7Configuration = new V7Configuration();

// sets angular to production mode (true) or dev mode (false)
environment.PRODUCTION = false; 

// sets path to where the backend aka v7 api is available
environment.API_URL = 'https://skillm8.synpulse.com/v7api/';

// sets the project language null means auto detect (it fetches the resources from backend from resources/locales/<language>/<namespace>.json)
// environment.LANGUAGE = 'de' | { lang: 'de', namespace: 'translation' };

// sets the time in ms how long v7 shall wait to lazy load connector states together to save RTT overhead
// environment.CONNECTOR_BUFFER_TIME = 25;

// debugging settings
// LOG_LEVEL defines the amount of logging information ("TRACE": 0, "DEBUG": 1, "INFO": 2, "WARN": 3, "ERROR": 4, "SILENT": 5)
// COMPONENT_INIT_DETECTION enables logging out a warning if a component was not properly initialized after time in ms - 0 means no logging
// TRANSLATION_DEBUGGING enabled debugging for translation
environment.DEBUG = {
    LOG_LEVEL: 'DEBUG',
    COMPONENT_INIT_DETECTION: 10000,
    TRANSLATION_DEBUGGING: true
};

// disables (true) or enables (false) the caching interceptor and caching as a whole
// environment.DISABLE_CACHING_INTERCEPTOR = true;

// enables (true) or disables (false) functional unit offline caching which makes v7 feel like a plain angular application
environment.FUNCTIONAL_UNITS_OFFLINE_CACHING = false;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
