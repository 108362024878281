import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiService } from "@profect/v7-core-lib";
import { Injectable } from "@angular/core";
import { SMTUserSkill, Skill, UserSkillDTO } from "../model/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserSkillService {
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient, protected apiService: ApiService) {}

  getControllerPath() {
    return `${this.apiService.getServerUrl()}v1/user-skill/`;
  }

  getCurrentUserSkills(): Observable<SMTUserSkill[]> {
    return this.http.get<SMTUserSkill[]>(
      `${this.getControllerPath()}current-user-skills`,
      this.httpOptions
    );
  }

  addSkill(userSkill: UserSkillDTO): Observable<SMTUserSkill> {
    return this.http.post<SMTUserSkill>(
      `${this.getControllerPath()}add-skill`,
      JSON.stringify(userSkill),
      this.httpOptions
    );
  }

  updateSkill(userSkill: SMTUserSkill): Observable<SMTUserSkill> {
    return this.http.put<SMTUserSkill>(
      `${this.getControllerPath()}update-skill`,
      JSON.stringify(userSkill),
      this.httpOptions
    );
  }

  removeSkill(id: number): Observable<any> {
    return this.http.delete<any>(
      `${this.getControllerPath()}remove-skill?id=` + id,
      this.httpOptions
    );
  }

  getSuggestedSkills(): Observable<Skill[]> {
    return this.http.get<Skill[]>(
      `${this.getControllerPath()}suggested-skills`,
      this.httpOptions
    );
  }

  getSkillBySkillId(id: number): Observable<SMTUserSkill[]> {
    return this.http.get<SMTUserSkill[]>(
      `${this.getControllerPath()}by-skill?skillId=` + id,
      this.httpOptions
    );
  }
}
