import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Certificate, CertificateDTO} from 'src/app/model/models';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CertificateService } from 'src/app/services/certificate.service';
import { ConfirmationDialog } from '../confirmation-dialog';

@Component({
  selector: 'app-admin-certifications-overview',
  templateUrl: './admin-certifications-overview.component.html',
  styleUrls: ['./admin-certifications-overview.component.scss']
})
export class AdminCertificationsOverviewComponent implements OnInit {

 

 // Mat table vars
 @Input() dataSource = new MatTableDataSource<Certificate>();
 displayedColumns: string[] = ['id','name','monthsToExpire','actions'];
 selection = new SelectionModel<Certificate>(true, []);

 @ViewChild(MatPaginator) paginator: MatPaginator;
 @Output() setPaginatorEvent = new EventEmitter(); 
 // Mode vars
 editMode: boolean = false;
 addCertificateMode: boolean = false;

 // Certificate vars
 @Input() allCertificates: Certificate[];
 selectedCertificate: Certificate;
 selectedCertificateWithoutChanges: Certificate;
 monthsToExpire: number;
 newCertificate: CertificateDTO = {
   name: "",
   monthsToExpire: 0,
   addedBy: "",
   additionalComments: "",
   isActive: true
 }

 

 // Other vars
 @Input() pageFullSizeOption: number;

 constructor (
   private adminPageService: AdminService,
   public dialog: MatDialog,
   private _snackBar: MatSnackBar
 ) { 
  
 }

 ngOnInit(): void {  
  this.getAllCertificates();
 }

 

 getAllCertificates() {
   this.adminPageService.getAllCertificates().subscribe(
     certificates => {        
       certificates.sort((a,b) => a.id - b.id);
       this.allCertificates = certificates;
       this.dataSource = new MatTableDataSource<Certificate>(this.allCertificates);
       this.dataSource.paginator = this.paginator;  
       this.pageFullSizeOption = this.allCertificates.length;
       this.setPaginator();
     }
   );
 }
   
 setPaginator() {
  this.setPaginatorEvent.emit(this.paginator);
 }

 editCertificate(certificate: Certificate) {
   if(this.selectedCertificate) {
     for(let property in this.selectedCertificate) {
       if(!this.selectedCertificate[property]) {
         this.selectedCertificate[property] = this.selectedCertificateWithoutChanges[property];
       }
     }
   }
   this.selectedCertificateWithoutChanges = {...certificate};
   this.selectedCertificate = certificate;
   this.editMode = true;
   
 }


 saveCertificateChanges() {
   if(this.selectedCertificate.name) {
     this.trimObjectFields(this.selectedCertificate);
     this.adminPageService.editCertificate(this.selectedCertificate).subscribe();
     this.selectedCertificate = undefined;
     this.editMode = false;
   } else {
     this.openSnackBar("Don't leave empty fields!","close");
   }
 }

 applyFilter(event: Event) {
   const filterValue = (event.target as HTMLInputElement).value;
   this.dataSource.filter = filterValue.trim().toLowerCase();
 }

 setAddCertificateMode() {
   this.addCertificateMode = !this.addCertificateMode;
 }

 addNewCertificate() {
   if(this.allCertificates.some(item => item.name.trim() === this.newCertificate.name.trim())) {
     this.openSnackBar(`Certificate: "${this.newCertificate.name}" already exists!`,'Close');
   } else {
     
     this.trimObjectFields(this.newCertificate);
     this.adminPageService.addCertificate(this.newCertificate).subscribe(
       data => {
         this.getAllCertificates();
         this.dataSource._updateChangeSubscription();
         this.openSnackBar(`Certificate "${this.newCertificate.name}" was added to list`,"Close");
       }
     );
   }    
 }

 openDialog(id: number): void {
  const dialogRef = this.dialog.open(ConfirmationDialog, {
    width: '330px',
    data: {componentName: 'certificate'}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');     
    if(result) {
      this.adminPageService.removeCertificate(id).subscribe(
        data => {
          this.getAllCertificates();
        }
      ); 
    }   
  });
}

 removeCertificate(id: number) {
  this.openDialog(id);
 }

 isCertificateActive(event,certificate) {
   certificate.isActive = event.checked;
   this.adminPageService.editCertificate(certificate).subscribe();
   this.dataSource._updateChangeSubscription();
 }

 setCertificateExpiration(value) {
  this.newCertificate.monthsToExpire = value;
 }

 openSnackBar(message: string, action: string) {
   this._snackBar.open(message, action);
 }

 trimObjectFields(object: any) {
   for(let property in object) {
     if(typeof object[property] == "string") {
       object[property] = object[property].trim();              
     }
   }
 }
}
