import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiService } from '@profect/v7-core-lib';
import { reject } from 'lodash';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserContextService } from '../user-context.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public userContextService: UserContextService, public router: Router,
    @Inject(DOCUMENT) private document: Document, protected apiService: ApiService) {
      
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

     return new Promise((resolve, reject) => {
      if (this.userContextService.getUser() == null) {
        this.userContextService.refresh().subscribe(user => {
          if (user == null) {
            this.document.location.href = environment.API_URL + "login";
            resolve(false);
          } else {
            this.userContextService.getUserSubscription().next(user);
            resolve(true);
          }
        })
      } else {
        resolve(true);
      }
     });
  }


}
