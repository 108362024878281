import { AfterViewInit, Component, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Skill, SkillDTO } from 'src/app/model/models';
import { SkillService } from 'src/app/services/skill.service';
import { AdminPopupComponent } from '../admin-popup/admin-popup.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { AdminService } from 'src/app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})

export class AdminPageComponent implements OnInit {

  allSkills: Skill[];
  dataSource = new MatTableDataSource<Skill>();
  pageFullSizeOption: number;
  paginator: MatPaginator;

  constructor (
    private skillService: SkillService,
    private adminPageService: AdminService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {  
             
  }

  getAllSkills() {
    this.adminPageService.getAllSkills().subscribe(
      skills => {        
        skills.sort((a,b) => a.id - b.id);
        this.allSkills = skills;
        this.dataSource = new MatTableDataSource<Skill>(this.allSkills);
        this.dataSource.paginator = this.paginator;  
        this.pageFullSizeOption = this.allSkills.length;
      }
    );
  }
    
  getPaginator(paginator: any) {
    this.paginator = paginator;
  }

  onAdminClick() {
    const dialogRef = this.dialog.open(AdminPopupComponent, {
      panelClass: 'admin-modal-page'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllSkills();
    });
  }

  // Navigation to top and and to the bottom of the page
  goToTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'auto' 
    });
  }

  goToBottom() {
    window.scroll({ 
      left: 0, 
      top: document.body.scrollHeight, 
      behavior: 'auto' 
    });
  }

}
