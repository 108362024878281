import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import {
  DEFAULT_ROUTES,
  RouterComponent,
  V7CoreLibModule,
  V7_CONFIG,
} from "@profect/v7-core-lib";
// import { V7DatasourceSqlLibModule } from '@profect/v7-datasource-sql-lib';
// import { V7DataviewChartLibModule } from '@profect/v7-dataview-chart-lib';
// import { V7DataviewCompassLibModule } from '@profect/v7-dataview-compass-lib';
// import { V7DataviewDashboardLibModule } from '@profect/v7-dataview-dashboard-lib';
// import { V7DataviewMapchartLibModule } from '@profect/v7-dataview-mapchart-lib';
// import { V7DataviewSelectionLibModule } from '@profect/v7-dataview-selection-lib';
// import { V7DmsLibModule } from '@profect/v7-dms-lib';
// import { V7FormbuilderLibModule } from '@profect/v7-formbuilder-lib';
import { environment } from "../environments/environment";
import { MaterialModule } from "./modules/material.module";
import { AppRoutingModule } from "./modules/app-routing.module";
import { AdminPopupComponent } from "./components/admin-popup/admin-popup.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchFilter } from "./pipes/search-filter.pipe";
import { SkillOverviewComponent } from "./components/skill-overview/skill-overview.component";
import { SearchFieldComponent } from "./components/search-field/search-field.component";
import { ScrollIntoViewDirective } from "./directives/scroll-into-view.directive";
import { AdminPageComponent } from "./components/admin-page/admin-page.component";
import { HeaderComponent } from "./components/header/header.component";
import { UniqueArrPipe } from "./pipes/unique-arr.pipe";
import { MainPageComponent } from "./components/main-page/main-page.component";
import { LanguageOverviewComponent } from "./components/language-overview/language-overview.component";
import { UserDataTableComponent } from "./components/user-data-table/user-data-table.component";
import { SearchContainerComponent } from "./components/search-container/search-container.component";
import { AdminSkillOverviewComponent } from "./components/admin-skill-overview/admin-skill-overview.component";
import { AdminLanguageOverviewComponent } from "./components/admin-language-overview/admin-language-overview.component";
import { CertificationsOverviewComponent } from "./components/certifications-overview/certifications-overview.component";
import { AdminCertificationsOverviewComponent } from "./components/admin-certifications-overview/admin-certifications-overview.component";
import { AdminUsersOverviewComponent } from "./components/admin-users-overview/admin-users-overview.component";
import { ConfirmationDialog } from "./components/confirmation-dialog";
import { FindSkillOverviewComponent } from "./components/find-skill-overview/find-skill-overview.component";

registerLocaleData(localeDe, "de");

@NgModule({
  declarations: [
    AdminPopupComponent,
    SearchFilter,
    SkillOverviewComponent,
    SearchFieldComponent,
    ScrollIntoViewDirective,
    AdminPageComponent,
    HeaderComponent,
    UniqueArrPipe,
    MainPageComponent,
    LanguageOverviewComponent,
    UserDataTableComponent,
    SearchContainerComponent,
    AdminSkillOverviewComponent,
    AdminLanguageOverviewComponent,
    CertificationsOverviewComponent,
    AdminCertificationsOverviewComponent,
    AdminUsersOverviewComponent,
    ConfirmationDialog,
    FindSkillOverviewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    V7CoreLibModule,
    MaterialModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    // V7DatasourceSqlLibModule,
    // V7DataviewChartLibModule,
    // V7DataviewCompassLibModule,
    // V7DataviewMapchartLibModule,
    // V7DataviewSelectionLibModule,
    // V7DataviewDashboardLibModule,
    // V7DmsLibModule,
    // V7FormbuilderLibModule
  ],
  providers: [{ provide: V7_CONFIG, useValue: environment }],
  bootstrap: [RouterComponent],
})
export class V7AppModule {}
