import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ClassService } from "@profect/v7-core-lib";
import { Skill } from '../model/models';


@Injectable({
  providedIn: 'root'
})
export class SkillService {

  

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" })
  };
  
  constructor(private http:HttpClient,protected apiService: ApiService) { }

  getControllerPath() {
    return `${this.apiService.getServerUrl()}v1/skill/`;
  }

  getAllSkills():Observable<Skill[]> {
    return this.http.get<Skill[]>(`${this.getControllerPath()}all`,
    this.httpOptions);
  }


  
  getSkillSubgroup(groupName: string):Observable<string[]> {
    return this.http.post<string[]>(`${this.getControllerPath()}group?groupName=${groupName.replace("&","%26")}`,
    this.httpOptions);
  }


  getSkills(subgroupName: string):Observable<Skill[]> {
    return this.http.post<Skill[]>(`${this.getControllerPath()}subgroup?subgroupName=${subgroupName.replace("&","%26")}`,
    this.httpOptions);
  }

  getSkillGroupList():Observable<string[]> {
    return this.http.get<string[]>(`${this.getControllerPath()}group-list`,
    this.httpOptions);
  }

  getSkillSubgroupList():Observable<string[]> {
    return this.http.get<string[]>(`${this.getControllerPath()}subgroup-list`,
    this.httpOptions);
  }

  getSkillNameList():Observable<string[]> {
    return this.http.get<string[]>(`${this.getControllerPath()}skill-name-list`,this.httpOptions);
  }

}
