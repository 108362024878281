<table mat-table  [dataSource]="dataSource" class="w100 mb-2">

    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef class="user-data-table-th">
            {{column | titlecase}}
        </th>
        <td mat-cell *matCellDef="let element" class="user-data-table-td">    
            <div>
                <span *ngIf="column !== 'actions'; else actions">{{column == 'dateOfIssue' && (element[column] | date: 'short') || column == 'level' && displayLevel(element[column]) || element[column]?.name || element[column]}}</span>
            </div> 
            <ng-template #actions>
                <button class="btn-actions" (click)="setEditMode(element)">
                    <mat-icon class="edit-ico">edit</mat-icon>
                </button>
                <button class="btn-actions">
                    <mat-icon class="delete-ico" (click)="getItemId(element.id)">delete</mat-icon>
                </button>
            </ng-template>
        </td>
    </ng-container>
                       
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
