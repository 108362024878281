import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Role, SMTUserSkill, Skill } from "src/app/model/models";
import { SkillService } from "src/app/services/skill.service";
import { UserSkillService } from "src/app/services/user-skill.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-find-skill-overview",
  templateUrl: "./find-skill-overview.component.html",
  styleUrls: ["./find-skill-overview.component.scss"],
})
export class FindSkillOverviewComponent implements OnInit {
  countryCodeMap = {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua And Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia And Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, Democratic Republic",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: 'Cote D"Ivoire',
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island & Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic Of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle Of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KR: "Korea",
    KP: "North Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: 'Lao People"s Democratic Republic',
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States Of",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena",
    KN: "Saint Kitts And Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre And Miquelon",
    VC: "Saint Vincent And Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome And Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia And Sandwich Isl.",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard And Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad And Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks And Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis And Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };

  // Search field vars
  searchFieldLabels: string[] = ["Group", "Subgroup", "Skill"];
  locationFieldLabel: string = "Location Filter";

  groupSearchFieldControl = new FormControl();
  subgroupSearchFieldControl = new FormControl();
  skillSearchFieldControl = new FormControl();
  locationFilterFieldControl = new FormControl();

  groupSearchFieldValue: string;
  subgroupSearchFieldValue: string;
  skillSearchFieldValue: string;

  // Select vars

  selectedGroup: string;
  selectedSubgroup: string;
  selectedSkill: Skill;
  selectedUserSkill: SMTUserSkill = {} as SMTUserSkill;

  // Search List vars

  skillGroupList: string[];
  skillSubgroupList: string[];
  skillNameList: string[];

  // User vars

  userSkills: SMTUserSkill[] = [];
  userSkillLevel: number;

  userRoles: Role[] = [];
  lastUpdateAt: Date;

  // Skill vars
  skillsList: Skill[] = [];

  // User skill vars
  userSkillsList: SMTUserSkill[] = [];

  // Mat table vars
  displayedColumns: string[] = ["person", "skillLevel", "email", "location"];
  dataSource = new MatTableDataSource<SMTUserSkill>();
  @ViewChild(MatSort) sort: MatSort;

  // Other vars
  Arr = Array;
  levelValueList: number[] = [1, 2, 3, 4, 5];
  hoveredStars: number;
  isIdenticalSubgroup = false;

  constructor(
    private skillService: SkillService,
    private userSkillService: UserSkillService,
    private userService: UserService,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog
  ) {}

  announceSortChange(sortState: Sort) {
    this.refreshTable();
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  // Get data
  getData() {
    this.loadSkills();

    this.skillService.getAllSkills().subscribe((skillList) => {
      this.skillsList = skillList.sort(this.compareSkillName);
    });

    this.skillService
      .getSkillGroupList()
      .subscribe(
        (skillGroupList) => (this.skillGroupList = skillGroupList.sort())
      );

    this.skillService
      .getSkillSubgroupList()
      .subscribe(
        (skillSubgroupList) =>
          (this.skillSubgroupList = skillSubgroupList.sort())
      );

    this.skillService
      .getSkillNameList()
      .subscribe(
        (skillNameList) => (this.skillNameList = skillNameList.sort())
      );
  }

  getLastUpdateDate(): void {
    this.userService
      .getUserTimestamp()
      .subscribe((date) => (this.lastUpdateAt = date));
  }

  // Skills actions
  skillInList(skill: Skill): boolean {
    let userSkill = this.userSkills.find((us) => us.skill.id == skill.id);
    if (userSkill != null || userSkill != undefined) return true;
    return false;
  }

  // Select actions

  selectGroup(skillGroup: string) {
    this.selectedSkill = undefined;
    this.selectedSubgroup = undefined;

    this.selectedGroup = skillGroup;

    this.skillService.getSkillSubgroup(skillGroup).subscribe((subgroups) => {
      if (subgroups.length == 1 && this.selectedGroup == subgroups[0]) {
        this.isIdenticalSubgroup = true;
        this.selectSubgroup(subgroups[0]);
        this.skillSubgroupList = [];
      } else {
        this.isIdenticalSubgroup = false;
        this.skillSubgroupList = subgroups.sort();
      }
    });
  }

  selectSubgroup(skillSubgroup) {
    this.selectedSubgroup = skillSubgroup;
    this.selectedSkill = undefined;
    this.skillService.getSkills(skillSubgroup).subscribe((skills) => {
      if (this.selectedGroup) {
        this.selectedGroup = skills.filter(
          (skill) => skill.skillGroup == this.selectedGroup
        )[0].skillGroup;
      } else {
        this.selectedGroup = skills[0].skillGroup;
      }
      this.skillsList = [];
      for (let item of skills) {
        if (this.skillsList.indexOf(item) === -1) {
          this.skillsList.push(item);
        }
      }
      this.skillsList.sort(this.compareSkillName);
    });
  }

  selectSkill(skill: Skill) {
    this.selectedGroup = skill.skillGroup;
    this.selectedSubgroup = skill.skillSubgroup;
    this.selectedSkill = skill;

    this.userSkillService
      .getSkillBySkillId(skill.id)
      .subscribe((userSkills) => {
        this.userSkillsList = userSkills;
        this.dataSource = new MatTableDataSource<SMTUserSkill>(userSkills);
        this.dataSource.sort = this.sort;
      });
  }

  // Refresh actions
  refreshTable() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "person":
          return item.smtUser.displayName;
        case "skillLevel":
          return item.level;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource._updateChangeSubscription();
    this.getLastUpdateDate();
  }

  // Set actions
  setSearchFieldValueGroup(fieldValue: string) {
    this.groupSearchFieldValue = fieldValue;
  }

  setSearchFieldValueSubgroup(fieldValue: string) {
    this.subgroupSearchFieldValue = fieldValue;
  }

  setSearchFieldValueSkill(fieldValue: string) {
    this.skillSearchFieldValue = fieldValue;
  }

  setLocationFilterValue(fieldValue: string) {
    let lowercaseFieldValue = fieldValue.toLowerCase();
    let list = this.userSkillsList.filter(
      (us) => us.skill.id == this.selectedSkill.id
    );
    let filteredList = [];
    if (fieldValue === undefined || fieldValue.length === 0) {
      filteredList = list;
    } else {
      filteredList = list.filter((us) =>
        this.getLocation(us.smtUser.netSuiteId)
          .toLowerCase()
          .includes(lowercaseFieldValue)
      );
    }

    this.dataSource = new MatTableDataSource<SMTUserSkill>(filteredList);
    this.dataSource.sort = this.sort;
    this.refreshTable();
  }

  logout() {
    this.userService.logoutUser().subscribe();
  }

  // Data operations

  compareSkillName(a: Skill, b: Skill) {
    if (a.skillName < b.skillName) {
      return -1;
    }
    if (a.skillName > b.skillName) {
      return 1;
    }
    return 0;
  }

  loadSkills() {
    this.selectedSkill = undefined;
    this.selectedGroup = undefined;
    this.selectedSubgroup = undefined;
  }

  getLocation(netSuiteId: string): string {
    return this.countryCodeMap[netSuiteId.substring(3, 5)];
  }
}
