import { Inject, Injectable, Injector } from '@angular/core';
import { User, V7Configuration, V7_CONFIG } from '@profect/v7-core-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class UserContextService {

  private user:BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  )

  constructor(
    @Inject(V7_CONFIG) protected v7cfg: V7Configuration,
    protected http: HttpClient,
    protected injector: Injector
  ) { }

  public refresh(): Observable<any> {
    let url = `${this.v7cfg.API_URL}v1/get-current-user`;
    const httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get<any>(url, { headers: httpHeaders })
  }

  public getUser(): any {
    return this.user.value;
  }

  public getUserString(): string {
    return this.user.value.displayName;
  }

  public getUserSubscription(): BehaviorSubject<any> {
    return this.user;
  }
}
