<div class="container mt-4 d-flex flex-column justify-content-center align-items-center">
  <div class="w-100">
    <mat-card class="fitler d-flex justify-content-between">
      <div>
        <mat-form-field class="input-field">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Search for a record " #input>
        </mat-form-field>
      </div>
      <form  *ngIf="addLanguageMode" class="w-50">
        <div class="d-flex justify-content-end align-items-center">
          <mat-form-field class="input-field w-50">
            <mat-label>Name</mat-label>
            <input matInput name="name" #name="ngModel" placeholder="Enter language name" [(ngModel)]="newLanguage.name" pattern="[\x00-\x7F]+" required>
            <mat-hint *ngIf="name.errors?.['required'] && (name.dirty || name.touched)" style="color:red;"></mat-hint>
            <mat-hint *ngIf="name.errors?.['pattern']" style="color:red;"></mat-hint>             
          </mat-form-field>
          <button mat-raised-button class="btn-action btn-add" style="height:37px" type="submit"
           (click)="addNewLanguage()"
           [disabled]="name.invalid">
            <mat-icon class="btn-language-add-ico">add</mat-icon>
            Add to table
          </button>
        </div>
      </form>
    </mat-card>
    <div class="table-container mat-elevation-z8 mt-3">
        <table mat-table [dataSource]="dataSource">
    
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="admin-language-table-th"> No. </th>
            <td mat-cell *matCellDef="let language">{{language.id}}</td>
          </ng-container>          
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="admin-language-table-th"> Name </th>
            <td mat-cell *matCellDef="let language"> 
              <div *ngIf="editMode">
                <mat-form-field  *ngIf="editMode && selectedLanguage?.id === language.id" class="w-90 input-field">
                  <mat-label>Language</mat-label>
                  <input matInput placeholder="Change group" [(ngModel)]="selectedLanguage.name" [value]="selectedLanguage.name" pattern="[\x00-\x7F]+" required>                                                      
                </mat-form-field>                
              </div>
              
              <div *ngIf="selectedLanguage?.id !== language.id">
                {{language.name}}
              </div>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="admin-language-table-th">
                Actions
            </th>
            <td mat-cell *matCellDef="let language">
                <div class="d-flex align-middle">
                  <button class="btn-action-white-bg btn-edit" (click)="editLanguage(language)" *ngIf="selectedLanguage?.id !== language.id">
                    <mat-icon class="edit-ico">edit</mat-icon>
                  </button>
                  <button class="btn-actions">
                    <mat-icon class="delete-ico" (click)="removeLanguage(language.id)">delete</mat-icon>
                  </button> 
                  <button class="btn-action-white-bg" (click)="saveLanguageChanges()" *ngIf="editMode && selectedLanguage?.id === language.id">
                    <mat-icon class="save-ico">save</mat-icon>
                  </button>                  
                  <div class="pl-3">
                    <mat-checkbox class="mt-1" color="primary" (change)="isLanguageActive($event,language)" [checked]="language.isActive">
                      <span  *ngIf="!language.isActive" class="language-disabled font-italic ml-2">Disabled</span>
                    </mat-checkbox>                                                                                      
                  </div>                  
                </div>                 
            </td>            
        </ng-container> 
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator class="table-paginator" [pageSizeOptions]="[5, 10, 25,pageFullSizeOption]"                
                       showFirstLastButtons>
        </mat-paginator>
        <div class="action-panel">           
          <button mat-raised-button class="btn-action" (click)="setAddLanguageMode()">
            <mat-icon class="btn-language-add-ico btn-ico">add</mat-icon>
            Add language
          </button>          
        </div>
    </div>
  </div>
    <div class="spacer"></div>
</div>