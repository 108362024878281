<div class="container">
    <div class="language mt-4">
        <div class="language-overview d-flex justify-content-between">
            <div class="language-overview-card">             
                <mat-card>                
                    <mat-card-title class="d-flex justify-content-between align-items-center">
                        <div>{{viewTitle}}</div>
                    </mat-card-title>                
                    <mat-divider></mat-divider>
                    <mat-card-content>  
        
                        <app-user-data-table
                            [dataSource]="userLanguages"
                            [map]="languageLevelMap"
                            [displayedColumns]="displayedColumns"
                            (deleteEvent)="removeUserLanguage($event)"
                            (editEvent)="editUserLanguage($event)">
                        </app-user-data-table>
                                                                               
                    </mat-card-content>                
                </mat-card>
                <mat-card *ngIf="languageLevel" class="mt-3">
                    <mat-card-title>
                        <div>{{levelDescTitle}}</div>
                    </mat-card-title>
                    <mat-card-content>
                        <p class="text-justify p-3"><b>{{levelDescText.substr(0,levelDescText.indexOf(':'))}}</b>{{levelDescText.substr(levelDescText.indexOf(':'),levelDescText.length)}}</p>
                    </mat-card-content>
                </mat-card>
                <mat-card class="infobox-language-request mt-3 d-flex justify-content-between">
                    <div class="infobox-title d-flex align-items-center pr-3">
                        <h4 class="infobox-title-text">* Are you missing a language in the list? Send us a request for a new language addition.</h4>
                    </div>
                    <div class="infobox-actions d-flex align-items-center">
                        <a mat-raised-button class="infobox-btn btn-report-email" [href]="reportEmail">
                            <mat-icon>email</mat-icon>
                            Request language
                        </a>                
                    </div>                    
                </mat-card>
            </div>                                          
            <div class="aside-panel">
                <mat-card class="mb-3 add-lang-card">
                    <mat-card-title *ngIf="!editMode" class="add-lang-title">Add language</mat-card-title>
                    <mat-card-title *ngIf="editMode"  class="add-lang-title">Edit language</mat-card-title>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <div class="d-flex justify-content-between mt-3">
                            <mat-form-field class="w60 no-bottom-space" appearance="fill">
                                <mat-label>Language</mat-label>
                                <input type="text" #searchInput
                                       placeholder="Language"
                                       aria-label="Language"
                                       value="{{selectedLanguage?.name}}"
                                       matInput
                                       [formControl]="myControl"
                                       [matAutocomplete]="auto"
                                       >
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchValueSelect($event.option.value)">
                                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option.name}}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                            <mat-form-field class="w35 no-bottom-space">                      
                                <mat-label>Level</mat-label>
                                <mat-select [(ngModel)]="languageLevel" (valueChange)="setLanguageLevelDesc($event)">
                                    <mat-option [value]="languageLevelMap.get(level)"  *ngFor="let level of mapKeys">{{level}}</mat-option>                            
                                </mat-select>                            
                            </mat-form-field>
                        </div>                                                                        
                    </mat-card-content>
                    <mat-card-actions class="d-flex justify-content-end">    
                        <mat-divider></mat-divider>
                        <button *ngIf="!editMode" [disabled]="!selectedLanguage || !languageLevel" class="btn btn-action mt-3" mat-raised-button (click)="addNewUserLanguage()"><div class="btn-lang-action-inner"><mat-icon class="btn-lang-add-ico">add</mat-icon>Add language</div></button>
                        <button *ngIf="editMode" [disabled]="!selectedLanguage || !languageLevel" class="btn btn-action mt-3" mat-raised-button (click)="saveChanges()"><div class="btn-lang-action-inner"><mat-icon class="btn-lang-save-ico mr-1">save</mat-icon>Save changes</div></button>                                
                    </mat-card-actions>
                </mat-card>
                <!-- <app-search-container class="language-search-card" 
                    [searchList]="languages"
                    [userDataList]="userLanguages"
                    [objectName]="objectName"
                    (selectItemEvent)="selectLanguage($event)"
                    [property]="property"
                    [selectedItem]="selectedLanguage">
                </app-search-container>             -->
            </div>            
        </div>
    </div>
    <div class="spacer"></div>
</div>