<form>
    <mat-form-field class="search-field" appearance="fill">
      <mat-label>{{searchFieldLabel}}</mat-label>
      <input type="text"  
             (ngModelChange)="getSearchFieldValue($event)"                                  
             aria-label="Label"
             matInput
             [formControl]="Control"
             [(ngModel)]="searchValue"
             [matAutocomplete]="autoGroup">
             <button *ngIf="searchValue"  matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
              <mat-icon>close</mat-icon>
            </button>
            <button *ngIf="!searchValue"  matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
                <mat-icon>search</mat-icon>
              </button>
            
            <mat-autocomplete #autoGroup="matAutocomplete"></mat-autocomplete>
    </mat-form-field>
  </form>