<mat-toolbar class="p-2">

    <img src="../../../assets/logo_skillm8.png" class="logo ml-4" routerLink="/">
    
    <span class="align-right"></span>

      <button *ngIf="isAdmin()" mat-raised-button color="primary" class="account-menu-btn" (click)="setMenuWidth(accountMenuBtn,accountMenuWrapper)" [matMenuTriggerFor]="accountMenu" #accountMenuBtn>
        <mat-icon>account_circle</mat-icon>
          {{currentUser?.displayName}}
      </button>

      <button *ngIf="!isAdmin()" mat-raised-button color="primary" class="account-menu-btn">
        <mat-icon>account_circle</mat-icon>
          {{currentUser?.displayName}}
      </button>

      <mat-menu  #accountMenu="matMenu"  class="account-menu">
        <div #accountMenuWrapper class="account-menu-wrapper">
          <a routerLink="/admin" *ngIf="isAdmin() && getCurrentUrl() != '/admin'" mat-menu-item class="account-menu-item">Admin</a>
          <a routerLink="/" *ngIf="isAdmin() && getCurrentUrl() == '/admin'" mat-menu-item class="account-menu-item">Skill overview</a>
          
        </div>
    </mat-menu>

    <span class="offset-15"></span>
  
</mat-toolbar>

